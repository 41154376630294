
import React, { useState } from "react";
import { motion } from "framer-motion";
import aboutUsImage from "../img/banner/r.png"; // Import the image

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      <div style={{ textAlign: "center", position: "relative", width: "100%" }}>
        <h1
          style={{
            fontSize: "48px",
            fontFamily: "Lato, sans-serif",
            display: "inline-block",
            position: "relative",
            textAlign: "center",
          }}
        >
          Posh  <span style={{ color: "rgb(235, 195, 101)" }}>Training</span>
          <span
            style={{
              position: "absolute",
              bottom: "-8px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "50%",
              height: "2px",
              backgroundColor: "#000",
            }}
          />
        </h1>
      </div>

      <section className="about-area ptb-100" style={{ padding: "0px 0" }}>
        <div className="container">
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "flex-start", // Image fixed rahega, text badhega
              flexWrap: "wrap",
              marginTop: "-12px",
            }}
          >
            {/* Right Side: About Image (Fixed Position) */}
            <motion.div
              className="col-lg-6 col-md-12 text-center"
              initial={{ x: "100vw", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
              style={{
                position: "sticky", // Yeh Image ko fix karega
                top: "10px", // Yeh Image ko scroll ke time fix rakhega
              }}
            >
              <div>
                <img
                  src={aboutUsImage}
                  alt="About Us"
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "10px",
                    display: "block",
                    marginLeft: "auto",
                  }}
                />
              </div>
            </motion.div>

            {/* Left Side: About Content (Expanding Text) */}
            <motion.div
              className="col-lg-6 col-md-12"
              initial={{ x: "-100vw", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}

              style={{
                textAlign: "left",
                maxWidth: "600px",
                paddingLeft: "20px",
                transition: "max-height 0.5s ease-in-out", // Smooth expansion
                overflow: "hidden", // Extra content ko smoothly appear hone dega
              }}
            >
              <h1
                style={{
                  fontSize: "25px",
                  fontFamily: "Lato, sans-serif",
                  position: "relative",
                  display: "inline-block"
                }}
              >
                Posh Training   <span style={{ color: "rgb(235, 195, 101)" }}> For drivers</span>
                <span
                  style={{
                    position: "absolute",
                    bottom: "-8px",
                    left: "0",
                    width: "50%",
                    height: "2px",
                    backgroundColor: "#000",
                  }}
                />
              </h1>

              <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>Sri Shiva Travels</span>,
                we are committed to ensuring a safe, respectful, and comfortable travel
                experience for all passengers. As part of our dedication to safety and ethical conduct, we conduct
                POSH (Prevention of Sexual Harassment) training for all our drivers and staff. Drivers are our
                company backbone and we don't compromise when it comes to service. Each of our chauffeurs are
                trained and groomed before getting them into ground service. Through this initiative, we aim to create
                a secure and harassment-free travel environment for our customers while fostering professionalism
                and responsibility among our drivers.
              </p>

              {/* View More Section */}
              <div style={{ maxHeight: showMore ? "1000px" : "0px", overflow: "hidden", transition: "max-height 0.5s ease-in-out" }}>

                <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                  Your safety is our priority! Travel with confidence, knowing that our team is trained to uphold the
                  highest standards of respect and integrity.
                </p>

              </div>

              {/* View More Button */}
              <button
                onClick={() => setShowMore(!showMore)}
                style={{
                  marginTop: "10px",
                  backgroundColor: "#ebc365",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#000",
                  borderRadius: "5px",
                  transition: "background 0.3s ease-in-out",
                }}
              >
                {showMore ? "View Less" : "View More"}
              </button>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;


