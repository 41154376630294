



// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import OwlCarousel from 'react-owl-carousel';

// // Import images all the data
// import img1 from '../img/aaaservices/sandeep.png';
// import img2 from '../img/team/2.png';


// const OurLeadership = () => {
//   const teamMembers = [
//     { name: 'C Chandra Sekhar Reddy', jobTitle: 'CEO & Founder', imgSrc: img1 },
//     { name: 'Asha Jyothi G', jobTitle: 'Chief Client Relations Officer', imgSrc: img2 },
//     { name: 'Kiran Kumar M', jobTitle: 'Marketing Manager', imgSrc: img3 },
//     { name: 'Eswar Raju K', jobTitle: 'SA Manager', imgSrc: img4 },
//     { name: 'Priti Pragyan B', jobTitle: 'ITE Manager', imgSrc: img5 },
//   ];

//   const sectionStyle = {
//     // padding: '50px 0',
//     backgroundColor: 'rgb(242 242 242)',
//     marginTop:'-104px',
//   };

//   const headingStyle = {
//     fontSize: '48px',
//     fontWeight: '700',
//     color: '#10100e',
//     textAlign: 'center',
//     marginBottom: '30px',
//   };

//   const headingHighlightStyle = {
//     color: '#0a8194',
//     fontFamily: 'lato, sans-serif',
//   };

//   const flipCardStyle = {
//     perspective: '1000px', // Enables 3D effect
//     width: '100%',
//     height: '400px', // Increased height for better image visibility
//     marginBottom: '20px',
//   };

//   const flipCardInnerStyle = {
//     position: 'relative',
//     width: '100%',
//     height: '100%',
//     transition: 'transform 0.6s',
//     transformStyle: 'preserve-3d',
//   };

//   const flipCardFrontStyle = {
//     position: 'absolute',
//     width: '100%',
//     height: '100%',
//     backfaceVisibility: 'hidden',
//     background: '#fff',
//     borderRadius: '10px',
//     overflow: 'hidden',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     boxShadow: '0 6px 20px rgba(0, 0, 0, 0.5)',
//   };

//   const flipCardBackStyle = {
//     position: 'absolute',
//     width: '100%',
//     height: '100%',
//     backfaceVisibility: 'hidden',
//     background: '#0a8194',
//     color: '#fff',
//     transform: 'rotateY(180deg)',
//     borderRadius: '10px',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '20px',
//     textAlign: 'center',
//     boxShadow: '0 6px 20px rgba(0, 0, 0, 0.5)',
//   };

//   const carouselOptions = {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//       0: {
//         items: 1, // Show 1 card on mobile
//       },
//       600: {
//         items: 2, // Show 2 cards on tablets
//       },
//       1000: {
//         items: 3, // Show 3 cards on desktops
//       },
//     },
//   };

//   return (
//     <section style={sectionStyle}>
//       <div className="container">
//         <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato,sans,serif', fontSize: '48px', }}>
//           <div class="text-center"> <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif', textAlign: 'center' }}> Our <span style={{ color: "rgb(235, 195, 101)" }}>Leadership</span>
//           </h1></div>
//           <div
//             style={{
//               width: '200px', // Adjust the width of the line
//               height: '2px', // Thickness of the line
//               backgroundColor: '#000', // Black color
//               margin: '10px auto 0', // Center alignment and spacing
//               marginTop: '-10px'
//             }}
//           ></div>
//         </div>

//         <OwlCarousel className="owl-theme" {...carouselOptions}>
//           {teamMembers.map((member, index) => (
//             <div className="item" key={index}>
//               <div
//                 className="flip-card"
//                 style={flipCardStyle}
//                 onMouseEnter={(e) =>
//                   (e.currentTarget.firstChild.style.transform = 'rotateY(180deg)')
//                 }
//                 onMouseLeave={(e) =>
//                   (e.currentTarget.firstChild.style.transform = 'rotateY(0deg)')
//                 }
//               >
//                 <div className="flip-card-inner" style={flipCardInnerStyle}>
//                   {/* Front Side */}
//                   <div className="flip-card-front" style={flipCardFrontStyle}>
//                     <img
//                       src={member.imgSrc}
//                       alt={member.name}
//                       style={{
//                         width: '100%',
//                         height: '250px',
//                         objectFit: 'cover',
//                         borderRadius: '10px',
//                       }}
//                     />
//                     <div style={{ padding: '10px', textAlign: 'center' }}>
//                       <h3>{member.name}</h3>
//                       <p>{member.jobTitle}</p>
//                     </div>
//                   </div>

//                   {/* Back Side */}
//                   <div className="flip-card-back" style={flipCardBackStyle}>
//                     <h3>{member.name}</h3>
//                     <p>{member.jobTitle}</p>
//                     <div style={{ marginTop: '15px' }}>
//                       <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
//                         <FontAwesomeIcon icon={faTwitter} />
//                       </a>
//                       <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
//                         <FontAwesomeIcon icon={faFacebook} />
//                       </a>
//                       <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
//                         <FontAwesomeIcon icon={faLinkedin} />
//                       </a>
//                       <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
//                         <FontAwesomeIcon icon={faInstagram} />
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </OwlCarousel>
//       </div>
//     </section>
//   );
// };

// export default OurLeadership;







// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
// // import 'owl.carousel/dist/assets/owl.carousel.css';
// // import 'owl.carousel/dist/assets/owl.theme.default.css';
// import OwlCarousel from 'react-owl-carousel';

// // Import images all the data
// import img1 from '../img/aaaservices/sandeep.png';
// import img2 from '../img/banner/onner.jpg';

// const OurLeadership = () => {
//   const teamMembers = [
//     {
//       name: 'SANDEEP -D',
//       jobTitle: 'Proprietor',
//       jobTitleSS: <p class="jobTitleSS">
//       <span class="sriShivaTravels">Sri Shiva Travels</span> is a trusted travel service provider, offering comfortable and reliable transportation solutions.
//   </p>,
//       imgSrc: img1,
//       linkedin: 'https://www.linkedin.com/in/chandrasekhar', 
//       twitter: 'https://twitter.com/chandrasekhar'
//     },
//     {
//       name: 'Jai -MK',
//       jobTitle: 'Operation Heads',
//       jobTitleSS: "",
//       imgSrc: img2,
//       linkedin: 'https://www.linkedin.com/in/ashajyothi',
//       twitter: 'https://twitter.com/ashajyothi'
//     }
//   ];

//   const carouselOptions = {
   
//     margin: 30, // Increased spacing between cards
//     nav: true,
//     responsive: {
//       0: { items: 2 },
//       600: { items: 2 },
//       1000: { items: 2 },
//     },
//   };

//   return (
//     <section style={{ backgroundColor: 'rgb(242 242 242)', }}>
//       <div className="container" style={{ maxWidth: '90%', margin: '0 auto',marginTop:'-77px',marginBottom:'20px' }}> {/* Increased width */}
//       <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato,sans,serif', fontSize: '48px', }}>
//            <div class="text-center"> <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif', textAlign: 'center' }}> Our <span style={{ color: "rgb(235, 195, 101)" }}>Leadership</span>
//            </h1></div>
//            <div
//              style={{
//                width: '200px', // Adjust the width of the line
//                height: '2px', // Thickness of the line
//                backgroundColor: '#000', // Black color
//                margin: '10px auto 0', // Center alignment and spacing
//                marginTop: '-10px'
//              }}
//            ></div>
//          </div>

//         <OwlCarousel className="owl-theme" {...carouselOptions}>
//           {teamMembers.map((member, index) => (
//             <div className="item" key={index}>
//               <div className="team-card">
//                 <img src={member.imgSrc} alt={member.name} className="team-image" />
//                 <div className="overlay">
//                   <h3>{member.name}</h3>
//                   <h3>{member.jobTitle}</h3>
//                   <h3>{member.jobTitleSS}</h3>
//                   <div className="social-icons">
//                   <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
//                     <FontAwesomeIcon icon={faLinkedin} />
//                   </a>
//                   <a href={member.twitter} target="_blank" rel="noopener noreferrer">
//                     <FontAwesomeIcon icon={faTwitter} />
//                   </a>
//                 </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </OwlCarousel>
//       </div>

//       <style>{`
//   .team-card {
//   position: relative;
//   width: 400px;
//   height: 400px;
//   overflow: hidden;
//   border-radius:  30px;
//   box-shadow: 0 6px 20pxrgb(235, 195, 101);
//   margin: 0 auto;
//   border: 1px solid rgb(235, 195, 101); /* Added yellow border */
  
// }


//   .team-image {
//     width: 350px;
//     height: 500px;
//     object-fit: cover;
//     border-radius: 20x;
//   }
// .jobTitleSS{
// font-family
// }
//   .overlay {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 100%; /* Full height, but hidden initially */
//   background: rgba(10, 129, 148, 0.7);
//   transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   opacity: 1;
//   transform: translateY(100%); /* Start from 80% bottom, so only 20% is visible */
//   border-radius: 10px;
// }
// .jobTitleSS {
//     font-family: 'Roboto', sans-serif;
// }

// .sriShivaTravels {
//     font-family: 'Arial', sans-serif; /* Isko apni pasand ka koi bhi font rakh sakte hain */
//     color: yellow;
//     font-size: 18px; /* Apni requirement ke according size change kar sakte hain */
//     text-align: left;
// }

// .team-card:hover .overlay {
//   transform: translateY(0); /* Moves up smoothly */
//   opacity: 1;
// }


//   .overlay h3 {
//     color: white;
//     font-size: 20px;
//     text-align: center;
//     opacity: 1;
//     transition: opacity 0.3s ease-in-out;
//   }

//   @media (max-width: 600px) {
//   .owl-stage {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   .team-card {
//     width: 240px;
//     height: auto;
//     margin-bottom: 20px;
//     margin-left: -45px;
//   }

//   .team-image {
//     width: 100%;
//     height: auto;
//   }
// }

// @media (max-width: 320px) {
//   .owl-stage {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   .team-card {
//     width: 240px;
//     height: auto;
//     margin-bottom: 20px;
//     margin-left: -61px;
//   }

//   .team-image {
//     width: 100%;
//     height: auto;
//   }
// }
//   .overlay {
//     height: auto;
//     padding: 20px;
//   }
//     .jobTitle{
//     font-size:12px;
//     }

//   .overlay h3 {
//     font-size: 18px;
//   }

//   .social-icons {
//           // margin-top: 10px;
//           display: flex;
//           gap: 15px;
//         }

//         .social-icons a {
        
//           font-size: 20px;
//           transition: color 0.3s ease-in-out;
//         }

//         .social-icons a:hover {
//           color: rgb(235, 195, 101);
//         }
// }
// `}</style>

//     </section>
//   );
// };

// export default OurLeadership;

import React from "react";
import image1 from "../img/aaaservices/sandeep.png";
import image2 from "../img/banner/onner2.png";

const CardComponent = () => {
  const styles = {
    cardContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "50px", // Added space between cards
      padding: "20px",
      flexWrap: "wrap",
    },
    card: {
      width: "100%",
      maxWidth: "400px",
      position: "relative",
      borderRadius: "10px",
      overflow: "hidden",
      border: "1px solid rgb(235, 195, 101)", // Added yellow border
    },
    cardImage: {
      width: "100%",
      height: "462px",
      objectFit: "cover",
    },
    cardInfo: {
      position: "absolute",
      bottom: "0",
      width: "100%",
      background: "rgba(0, 0, 0, 0.7)",
      color: "white",
      textAlign: "center",
      padding: "10px",
      transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
    },
    cardInfoHover: {
      animation: "blink 1s infinite", // Adding blink animation
    },
    cardTitle: {
      margin: "0",
      fontSize: "18px",
      color:'rgb(235, 195, 101)'
    },
    cardSubtitle: {
      margin: "0",
      fontSize: "14px",
    },
    
  };
  

  return (

    <div className="containersssss" style={{ maxWidth: '90%', margin: '0 auto',marginTop:'-77px',marginBottom:'20px' }}> {/* Increased width */}
       <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato,sans,serif', fontSize: '48px', }}>
            <div class="text-center"> <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif', textAlign: 'center' }}> Our <span style={{ color: "rgb(235, 195, 101)" }}>Leadership</span>
            </h1></div>
            <div
              style={{
                width: '200px', // Adjust the width of the line
               height: '2px', // Thickness of the line
                backgroundColor: '#000', // Black color
                margin: '10px auto 0', // Center alignment and spacing
                marginTop: '-10px'
              }}
           ></div>
          </div>
    <div style={styles.cardContainer}>


      <div
        style={styles.card}
        onMouseEnter={(e) => e.currentTarget.children[1].classList.add("blink")}
        onMouseLeave={(e) => e.currentTarget.children[1].classList.remove("blink")}
      >
        <img src={image1} alt="Card 1" style={styles.cardImage} />
        <div style={styles.cardInfo} className="card-info">
          <h3 style={styles.cardTitle}>SANDEEP D</h3>
          <p style={styles.cardSubtitle}>PROPRIETOR</p>
        </div>
      </div>
      <div
        style={styles.card}
        onMouseEnter={(e) => e.currentTarget.children[1].classList.add("blink")}
        onMouseLeave={(e) => e.currentTarget.children[1].classList.remove("blink")}
      >
        <img src={image2} alt="Card 2" style={styles.cardImage} />
        <div style={styles.cardInfo} className="card-info">
          <h3 style={styles.cardTitle}>JAI MK</h3>
          <p style={styles.cardSubtitle}>OPERATION HEAD</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CardComponent;

// Add this CSS to your styles to make the blinking effect
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }

  .blink {
    
  }
     @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
    .card {
      max-width: 100%;
    }

     @media (max-width: 425px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
    .card {
      max-width: 100%;
    }

     @media (max-width: 320px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
    .card {
      max-width: 100%;
    }
`;
document.head.appendChild(styleSheet);
