import React from 'react';
import bannerImage from '../img/logisticsimages/whowearebanners/image (29).png'; // Adjust the path according to your project structure

const WhoweareBanner = () => {
  return (
    <div className="hero-area overly-style-1 opacity-point-4">
      {/* Replace video with image */}
      <img 
        className="banner-image" 
        src={bannerImage} 
        alt="About AA Applications Banner" 
        style={{ width: '100%', height: '300px' }} 
      />
      
      <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
        <h1 style={{color:"#fff",fontSize:"48px", fontFamily:'Raqupine Regular'}}><span style={{fontFamily: 'Raqupine Regular', }}>LOGISTIC</span></h1>
        {/* Uncomment these if needed */}
        {/* <h1 className="title" data-aos="fade-up" data-aos-delay="200" style={{ color: '#fff' }}>Co Working</h1> */}
        {/* <p data-aos="fade-up" data-aos-delay="300">Quisque at justo sagittis, semper lacus a, iaculis tellus. Fusce tempor, leo vel iaculis aliquet,</p> */}
        {/* <a href="project.html" className="btn btn-primary btn-hover-dark" data-aos="fade-up" data-aos-delay="400">VIEW PROJECT</a> */}
      </div>
    </div>
  );
};

export default WhoweareBanner;



// import React from 'react';
// import bannerVideo from '../img/aaaservices/red.mp4'; // Adjust the path according to your project structure

// const WhoweareBanner = () => {
//   return (
//     <div className="hero-area overly-style-1 opacity-point-4">
//       {/* Replace image with video */}
//       <video 
//         className="banner-video" 
//         src={bannerVideo} 
//         autoPlay 
//         loop 
//         muted 
//         playsInline 
//         style={{ width: '100%', height: '914px', objectFit: 'cover' }}
//       >
//         Your browser does not support the video tag.
//       </video>
      
//       <div className="hero-content-1 hero-content-position" style={{ color: '#fff', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
//         <h1 style={{ color: "#fff", fontSize: "48px", fontFamily: 'Raqupine Regular',marginTop:'750px' }}>
//           <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span>
//         </h1>
//       </div>
//     </div>
//   );
// };

// export default WhoweareBanner;

