import React, { useEffect, useRef, useState } from "react";

const AnimatedBlocks = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight * 0.75) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container" ref={containerRef}>
      <div className={`block block-left last-block ${isVisible ? "animate" : ""}`}>
        <h3 style={{fontFamily:'Raqupine Regular',fontSize:"25px"}} className="rentarscars"> CREATIVE</h3>
        <p>We are constantly innovating and refining our methods to enhance efficiency and effectiveness. If we discover a better approach, we take immediate action, implement it, and share our insights.</p>
      </div>
      <div className={`block block-right mid-block ${isVisible ? "animate" : ""}`}>
        <h3 style={{fontFamily:'Raqupine Regular',fontSize:"25px", color:'white'}} className="rentarscars"> COMMITTED</h3>
        <p style={{color:'whitesmoke'}}>Deeply involved in building relationships – everything we do is with the long-term in mind. Our dedication to quality is the cornerstone of our success – we get every detail right.</p>
      </div>

      <div className={`block block-left ${isVisible ? "animate" : ""}`}>
        <h3 style={{fontFamily:'Raqupine Regular',fontSize:"25px"}} className="rentarscars"> CONNECTED</h3>
        <p>We invest in insights to deeply understand our customers' challenges. Our approach is rooted in transparency and professionalism, ensuring that we deliver data-driven solutions with integrity and expertise.</p>
      </div>
      
      
    </div>
  );
};

export default AnimatedBlocks;

const styles = `
.container {
  width: 80%;
  margin: 50px auto;
  max-width: 1200px; /* Prevents it from being too wide on large screens */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
    margin: 20px auto;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 95%;
    margin: 10px auto;
  }
}

.block {
  position: relative;
  padding: 20px;
  color: #000;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background: #e7bd63;
  margin: 20px 0;
  border-radius: 0px 130px 10px;
  opacity: 0;
  transform: translateX(0); /* Remove translateX animations */
  transition: opacity 1.5s ease-in-out;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width */
}

.block-left.animate,
.block-right.animate {
  opacity: 1;
}

.mid-block {
  background: rgb(49, 56, 64);
}

`;

const styleElement = document.createElement("style");
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);


