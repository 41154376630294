import React from 'react';
import Slider from "react-slick";
import iimg2 from '../img/banner/bike.jpg'
import iimg3 from '../img/aaaservices/employe.jpg'
import iimg4 from '../img/aaaservices/event.png';
import iimg1 from '../img/aaaservices/airport.webp';
import iimg5 from '../img/aaaservices/monthly.png'
import iimg6 from '../img/banner/TRAVLES2.jpg';
// import Commercial7 from '../img/banner/Commercial7.png';
import outdoorInteriorsImg from "../img/aaaservices/corporate.webp";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Services = () => {
  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
     autoplay: true,
      autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  return (
    <section className="services-boxes-area" style={{ backgroundColor: '#e6eeef' }}>
      <div className="">
        <Slider {...settings}>
          {/* <div className="single-box">
            <img src={iimg2} alt="Residential Interiors" />
            <div className="contents">
              <h3>
                <Link to="/it-services">Bike  </Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/it-services">Bike </Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular' }}> TRAVLES </span> Streamline your delivery operations with  Delivery Bike Services. Whether you're a business needing reliable delivery solutions or an individual requiring on-demand courier services, we offer:.</p>
              <Link to="/it-services" className="read-more-btn">Learn More</Link>
            </div>
          </div> */}

          <div className="single-box">
            <img src={iimg3} alt="Commercial Interiors" />
            <div className="contents">
              <h3>
                <Link to="/web-applications"  style={{color:'#f5c163'}}>Employee Transportation </Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/web-applications">Employee Transportation  </Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular',color:'#f5c163' }}>TRAVELS </span>   Car Services offer comprehensive solutions for all your vehicle needs, ensuring convenience, reliability, and quality</p>
              <Link to="/web-applications" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg4} alt="Hospitality Interiors" />
            <div className="contents">
              <h3>
                <Link to="/web-applications"  style={{color:'#f5c163'}}> Event Transportation </Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/web-applications"> Event Transportation</Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular',color:'#f5c163' }}>TRAVELS </span> 
             Road transportaion Services ensure your heavy-duty vehicles stay in top condition for the long haul. We provide reliable, efficient, and specialized services for trucks of all sizes.</p>
              <Link to="/web-applications" className="read-more-btn">Learn More</Link>
            </div>
          </div>


          <div className="single-box">
          <img src={outdoorInteriorsImg} alt="Outdoor Interiors" />

            <div className="contents">
              <h3>
                <Link to="/web-applications"  style={{color:'#f5c163'}}> Corporate Car</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/web-applications">Corporate Car </Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular',color:'#f5c163' }}>TRAVELS </span>
              Road transportaion Services ensure your heavy-duty vehicles stay in top condition for the long haul. We provide reliable, efficient, and specialized services for trucks of all sizes.</p>
              <Link to="/web-applications" className="read-more-btn">Learn More</Link>
            </div>
          </div>

          <div className="single-box">
            <img src={iimg1} alt="Showcase Interiors" />
            <div className="contents">
              <h3>
                <Link to="/web-applications"  style={{color:'#f5c163'}}>Airport Transfer</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/web-applications">Airport Transfer</Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular',color:'#f5c163' }}>TRAVELS </span>
             Import and Export Services are tailored to keep your passenger vehicles running safely and efficiently. Whether for public transportation, school buses, or private charters, we provide comprehensive care to meet your needs. <span style={{fontFamily: 'Raqupine Regular' }}> TRAVLES </span> </p>
              <Link to="/web-applications" className="read-more-btn">Learn More</Link>
            </div>

            
            
          </div>

          <div className="single-box">
            <img src={iimg5} alt="Showcase Interiors" />
            <div className="contents">
              <h3>
                <Link to="/web-applications"  style={{color:'#f5c163'}}>Monthly Rental</Link>
              </h3>
            </div>
            <div className="hover-content">
              <h3>
                <Link to="/web-applications">Monthly Rental</Link>
              </h3>
              <p><span style={{fontFamily: 'Raqupine Regular',color:'#f5c163' }}>TRAVELS </span>
             Import and Export Services are tailored to keep your passenger vehicles running safely and efficiently. Whether for public transportation, school buses, or private charters, we provide comprehensive care to meet your needs. <span style={{fontFamily: 'Raqupine Regular' }}> TRAVLES </span> </p>
              <Link to="/web-applications" className="read-more-btn">Learn More</Link>
            </div>

            
            
          </div>

          
        </Slider>
      </div>
    </section>
  );
};

export default Services;
