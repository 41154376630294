
import React from 'react';
import WhoweareBanner from './WhoweareBanner';
import Filter from './Filter';

const WhoWeAre = () => {
    return (
        <div>
            <h1>Who We Are</h1>
            <WhoweareBanner />
            <Filter />
        </div>
    );
};

export default WhoWeAre;
