
import React from 'react';
import AboutCompany from './AboutCompany';
import OurCommitments from './OurCommitments';
import ContactBanner from './ContactBanner';
import OurLeadership from './OurLeadership';
import OurTeam from './OurTeam';
const AboutPAGE = () => {
    return (
        <div>
           <ContactBanner/>
           <AboutCompany />
      <OurCommitments />
      <OurLeadership/>
      {/* <OurTeam/> */}
         
        </div>
    );
};

export default AboutPAGE;