import React, { useState } from "react";
import rollsRoyce from "../img/aaaservices/royls.png";
import bentley from "../img/aaaservices/l2.png";
import lamborghini from "../img/aaaservices/lem.png";
import ferrari from "../img/aaaservices/f1.png";
import maybach from "../img/aaaservices/bench.png";

const cars = [
  {
    name: "Rolls Royce",
    image: rollsRoyce,
    features: ["Handcrafted Interior", "Luxury AC", "GPS", "First Aid Kit", "4 Seats", "Premium Sound System"],
  },
  {
    name: "Bentley",
    image: bentley,
    features: ["Handcrafted Interior", "Luxury AC", "GPS", "First Aid Kit", "4 Seats", "Premium Sound System"],
  },
  {
    name: "Lamborghini",
    image: lamborghini,
    features: ["Handcrafted Interior", "Luxury AC", "GPS", "First Aid Kit", "4 Seats", "Premium Sound System"],
  },
  {
    name: "Ferrari",
    image: ferrari,
    features: ["Handcrafted Interior", "Luxury AC", "GPS", "First Aid Kit", "4 Seats", "Premium Sound System"],
  },
  {
    name: "Maybach",
    image: maybach,
    features: ["Handcrafted Interior", "Luxury AC", "GPS", "First Aid Kit", "4 Seats", "Premium Sound System"],
  },
];

const CarFilter = () => {
  const [selectedCar, setSelectedCar] = useState(cars[0]);

  return (
    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", gap: "16px", }}>
        {/* Car Filter Buttons */}
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {cars.map((car) => (
            <button
              key={car.name}
              style={{ padding: "10px 15px", border: "none", backgroundColor: selectedCar.name === car.name ? "#ffcc00" : "#f0f0f0", cursor: "pointer", borderRadius: "18px", fontWeight: "bold" }}
              onClick={() => setSelectedCar(car)}
            >
              {car.name}
            </button>
          ))}
        </div>
        {/* Car Image and Features */}
        <div style={{ textAlign: "center" }}>
          <img src={selectedCar.image} alt={selectedCar.name} style={{ width: "324px", height: "auto", borderRadius: "30px" }} />
          <div style={{ marginTop: "20px", display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center" }}>
            {selectedCar.features.map((feature, index) => (
              <span key={index} style={{ padding: "8px 12px", backgroundColor: "#007bff", color: "#fff", borderRadius: "4px", fontSize: "14px" }}>{feature}</span>
            ))}
          </div>
        </div>
        {/* Enquiry Form */}
        <div style={{ width: "300px", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", position: "relative" }}>
          <h3 style={{ textAlign: "center",color:'#ffca00' }}>Enquiry Form</h3>
          <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <input type="text" placeholder="Your Name" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <input type="email" placeholder="Your Email" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <input type="tel" placeholder="Your Phone" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <textarea placeholder="Your Message" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", resize: "none" }}></textarea>
            <button type="submit" style={{ padding: "10px", backgroundColor: "#ffcc00", border: "none", borderRadius: "4px", cursor: "pointer", fontWeight: "bold" }}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CarFilter;