import React, { useState } from "react";
import { motion } from "framer-motion";
import aboutUsImage from "../img/aaaservices/event.png"; // Import the image

const AboutUs = () => {
    const [showMore, setShowMore] = useState(false);

    return (
        <section className="about-area ptb-100" style={{ padding: "0px 0" }}>
            <div className="container" >
                <div
                    className="row"
                    style={{
                        display: "flex",
                        alignItems: "flex-start", // Image fixed rahega, text badhega
                        flexWrap: "wrap",
                        marginTop: "-12px",
                    }}
                >
                    {/* Right Side: About Image (Fixed Position) */}
                    <motion.div
                        className="col-lg-6 col-md-12 text-center"
                        initial={{ x: "100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        style={{
                            position: "sticky", // Yeh Image ko fix karega
                            top: "10px", // Yeh Image ko scroll ke time fix rakhega
                        }}
                    >
                        <div>
                            <img
                                src={aboutUsImage}
                                alt="About Us"
                                className="img-fluid"
                                style={{
                                    maxWidth: "100%",
                                    height: "400px",
                                    borderRadius: "10px",
                                    display: "block",
                                    marginLeft: "auto",
                                }}
                            />
                        </div>
                    </motion.div>

                    {/* Left Side: About Content (Expanding Text) */}
                    <motion.div
                        className="col-lg-6 col-md-12"
                        initial={{ x: "-100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}

                        style={{
                            textAlign: "left",
                            maxWidth: "600px",
                            paddingLeft: "20px",
                            transition: "max-height 0.5s ease-in-out", // Smooth expansion
                            overflow: "hidden", // Extra content ko smoothly appear hone dega
                        }}
                    >
                        <h1
                            style={{
                                fontSize: "30px",
                                fontFamily: "Lato, sans-serif",
                                position: "relative",
                                display: "inline-block"
                            }}
                        >
                            Events <span style={{ color: "rgb(235, 195, 101)" }}>Transportation</span>
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: "-8px",
                                    left: "0",
                                    width: "50%",
                                    height: "2px",
                                    backgroundColor: "#000",
                                }}
                            />
                        </h1>

                        <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                            <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>Sri Shiva Travels</span>,
                            is a one-stop shop to start and end your corporate and special events with
                            outstanding, reliable transportation.
                            We offer many advantages over other companies:
                            ∙Our experienced dispatchers and seasoned driving force know what it takes to get your
                            attendees to their destination safely and on time.
                            ∙Our extensive fleet means we can match the vehicles and services to your needs.
                            ∙One of the largest private fleets in the region, so we’ll always have a vehicle for you and
                            ensure that you and your group will never be stranded.
                        </p>

                        {/* View More Section */}
                        <div style={{ maxHeight: showMore ? "1000px" : "0px", overflow: "hidden", transition: "max-height 0.5s ease-in-out" }}>
                            <h5
                                style={{
                                    fontSize: "20px",
                                    fontFamily: "Lato, sans-serif",
                                    position: "relative",
                                    display: "inline-block"
                                }}
                            >
                                Professional <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "-8px",
                                        left: "0",
                                        width: "50%",
                                        height: "2px",
                                        backgroundColor: "#000",
                                    }}
                                />
                            </h5>
                            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                                We provide top-tier transportation solutions for corporate gatherings and special events, ensuring a seamless experience from start to finish. With a highly skilled team of dispatchers and professional drivers, we guarantee safe, punctual, and stress-free travel for all attendees.
                            </p>
                            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                                Our extensive fleet offers a variety of vehicle options tailored to meet diverse event needs, from executive shuttles to luxury transport. As one of the largest private fleets in the region, we ensure availability at all times, so you and your guests are never left without a ride.

                                Choose reliability, efficiency, and excellence—trust us to handle your event transportation needs with precision and care.
                            </p>
                        </div>

                        {/* View More Button */}
                        <button
                            onClick={() => setShowMore(!showMore)}
                            style={{
                                marginTop: "10px",
                                backgroundColor: "#ebc365",
                                border: "none",
                                padding: "10px 20px",
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#000",
                                borderRadius: "5px",
                                transition: "background 0.3s ease-in-out",
                            }}
                        >
                            {showMore ? "View Less" : "View More"}
                        </button>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;