import React, { useState } from "react";
import { motion } from "framer-motion";
import aboutUsImage from "../img/aaaservices/corporate.webp"; // Import the image

const AboutUs = () => {
    const [showMore, setShowMore] = useState(false);

    return (
        <section className="about-area ptb-100" style={{ padding: "0px 0" }}>
            <div className="container" >
                <div
                    className="row"
                    style={{
                        display: "flex",
                        alignItems: "flex-start", // Image fixed rahega, text badhega
                        flexWrap: "wrap",
                        marginTop: "-12px",
                    }}
                >
                    {/* Right Side: About Image (Fixed Position) */}
                    <motion.div
                        className="col-lg-6 col-md-12 text-center"
                        initial={{ x: "100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        style={{
                            position: "sticky", // Yeh Image ko fix karega
                            top: "10px", // Yeh Image ko scroll ke time fix rakhega
                        }}
                    >
                        <div>
                            <img
                                src={aboutUsImage}
                                alt="About Us"
                                className="img-fluid"
                                style={{
                                    maxWidth: "100%",
                                    height: "400px",
                                    borderRadius: "10px",
                                    display: "block",
                                    marginLeft: "auto",
                                }}
                            />
                        </div>
                    </motion.div>

                    {/* Left Side: About Content (Expanding Text) */}
                    <motion.div
                        className="col-lg-6 col-md-12"
                        initial={{ x: "-100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}

                        style={{
                            textAlign: "left",
                            maxWidth: "600px",
                            paddingLeft: "20px",
                            transition: "max-height 0.5s ease-in-out", // Smooth expansion
                            overflow: "hidden", // Extra content ko smoothly appear hone dega
                        }}
                    >
                        <h1
                            style={{
                                fontSize: "30px",
                                fontFamily: "Lato, sans-serif",
                                position: "relative",
                                display: "inline-block"
                            }}
                        >
                            Corporate  <span style={{ color: "rgb(235, 195, 101)" }}>Car</span>
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: "-8px",
                                    left: "0",
                                    width: "50%",
                                    height: "2px",
                                    backgroundColor: "#000",
                                }}
                            />
                        </h1>

                        <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                            <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>Sri Shiva Travels</span>,
                            is equipped with high end fleet of vehicles; you can book as per your
                            corporate needs. Whether you have group of delegates coming or couple of top company associates,
                            we have fleet of vehicles to offer transfer service accordingly. In fact, we have tie-ups with many top
                            companies to pick and drop company employees and associates regularly.Our corporate car rental service is spread across all major companies. Corporate organizations need
                            to devote their precious time on implementing transport service to employees. This gets eliminated
                            when you connect us where we offer customized corporate car service. We help in implementing the
                            transport facilities as per the destination of employees.
                        </p>

                        {/* View More Section */}
                        <div style={{ maxHeight: showMore ? "1000px" : "0px", overflow: "hidden", transition: "max-height 0.5s ease-in-out" }}>
                            <h5
                                style={{
                                    fontSize: "20px",
                                    fontFamily: "Lato, sans-serif",
                                    position: "relative",
                                    display: "inline-block"
                                }}
                            >
                                Professional <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "-8px",
                                        left: "0",
                                        width: "50%",
                                        height: "2px",
                                        backgroundColor: "#000",
                                    }}
                                />
                            </h5>
                            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                                You can connect with us, understand the package and book it accordingly for long term car rental
                                service. We got fleet of cars for employees working in different positions. So you can book accordingly
                                and help employee commute easily.
                                <div style={{ fontFamily: "Arial, sans-serif", padding: "20px", fontSize: '16px' }}>
                                    <h2 style={{ color: "rgb(235, 195, 101)", textAlign: "left", fontSize: "16px", marginBottom: "15px" }}>
                                        Reasons to Book Our Corporate Car Rental
                                    </h2>
                                    <ul style={{ listStyleType: "none", padding: "0" }}>
                                        <li style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                                            <strong style={{ color: "rgb(235, 195, 101)" }}>✔ One-Point Contact:</strong> <p  style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>Easily manage bookings with a dedicated representative.</p>
                                        </li>
                                        <li style={{ fontSize: "16px", marginBottom: "10px" }}>
                                            <strong style={{ color: "rgb(235, 195, 101)" }}>✔ Professional Chauffeurs:</strong><p  style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}> Our drivers undergo strict training and security checks to ensure top-tier service.</p>
                                        </li>
                                        <li style={{ fontSize: "18px", marginBottom: "10px" }}>
                                            <strong style={{ color: "rgb(235, 195, 101)" }}>✔ Transparent Process:</strong><p  style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}> Enjoy complete clarity in booking and billing with no hidden charges.</p>
                                        </li>
                                    </ul>
                                </div>
                            </p>
                            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                                The best thing about booking our corporate car rental is the fleet of luxury and economical cars
                                available. Our primary goal is to offer professional service to corporates. From first-aid-kit to GPRS
                                system, our fleet of cars are equipped all the necessary things required during car journey. Our
                                premium segments of car are for top company executives to offer comfort and luxury transfer
                                experience. Connect with us to know more about our Corporate Car service.
                            </p>
                        </div>

                        {/* View More Button */}
                        <button
                            onClick={() => setShowMore(!showMore)}
                            style={{
                                marginTop: "10px",
                                backgroundColor: "#ebc365",
                                border: "none",
                                padding: "10px 20px",
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#000",
                                borderRadius: "5px",
                                transition: "background 0.3s ease-in-out",
                            }}
                        >
                            {showMore ? "View Less" : "View More"}
                        </button>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;