import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import picture1 from "../img/aaaservices/amjon.png";
import picture2 from "../img/aaaservices/Optum-logo.png";
import picture3 from "../img/aaaservices/Carlsberg-logo.png";
import picture4 from "../img/aaaservices/wns.png";
import picture5 from "../img/aaaservices/wipro.png";
import picture6 from "../img/aaaservices/cartflo.png";
import picture7 from "../img/aaaservices/kynes.png";

import picture9 from "../img/aaaservices/wilfargo.png";

import picture11 from "../img/aaaservices/carelon.png";

const OurCoPartners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,  // Normal smooth sliding speed
    slidesToShow: 5,
    slidesToScroll: 1,  // Auto slide 3-3
    autoplay: true,
    autoplaySpeed: 2000,  // Set a delay for better auto-sliding
    pauseOnHover: false,
    arrows: false,
    responsive: [
      { breakpoint: 1440, settings: { slidesToShow: 5, slidesToScroll: 3 } },
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    ],
  };
  
  

  return (
    <div style={sectionStyle}>
      <h1 style={headingStyle}>
        Our <span style={{ color: "rgb(235, 195, 101)" }}>Clients</span>
      </h1>
      <div style={underlineStyle}></div>
      <div style={{ width: "110%" }}>
        <Slider {...settings}>
          {[picture1, picture2, picture3, picture4, picture5, picture6, picture7,,picture9,picture11,].map((image, index) => (
            <div key={index} style={cardStyle}>
              <div style={circleContainerStyle}>
                <img src={image} alt={`Partner ${index + 1}`} style={imageStyle} />
                <div style={overlayStyle} className="overlay">
                
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

const sectionStyle = {
  textAlign: "center",
  padding: "40px 20px",
  marginTop:'-50px'
};

const headingStyle = {
  fontSize: "48px",
  fontFamily: "Lato, sans-serif",
  position: "relative",
  display: "inline-block",
};

const underlineStyle = {
  width: "200px",
  height: "2px",
  backgroundColor: "#000",
  margin: "10px auto",
  marginTop:'-20px'
};

const cardStyle = {
  display: "flex",
  justifyContent: "center",
};

const circleContainerStyle = {
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  overflow: "hidden",
  position: "relative",
  border: "5px solid #eae9f9",
  boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  cursor: "pointer",
};

const imageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
  transition: "0.3s ease",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0,
  transition: "opacity 0.3s ease",
};

const overlayTextStyle = {
//   color: "#fff",
//   fontSize: "16px",
//   fontWeight: "bold",
};

export default OurCoPartners;
