import React from "react";
import breadcumb from "../img/who.jpg"; // Ensure correct image path

const ContactBanner = () => {
  return (
    <section
      className="breadcumb"
      aria-label="breadcumb"
      style={{
        backgroundImage: `url(${breadcumb})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "600px", // Adjust height as needed
        display: "flex",
        marginTop:'20px',
        alignItems: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-10">
            {/* Left Side Banner Text */}
            <div className="bread" style={{marginTop:'507px'}}>
              <div
                className="bread-title"
                style={{
                  fontFamily: "Raqupine Regular",
                  color: "#fff",
                  fontSize: "18px",
                  marginBottom: "5px",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                }}
              >
                Welcome to
              </div>
              <div
                className="bread-title"
                style={{
                  fontFamily: "Raqupine Regular",
                  color: "rgb(235, 195, 101)",
                  fontSize: "25px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
              SRI SHIVA TRAVELS
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBanner;
