import React, { useEffect } from 'react';
import Services from './Services'
import Aboutus from './Aboutus'
import MainBanner from './MainBanner'
import Stack from './Stack'
import Whychoose from './Whychoose'
//  import Gallery from './Gallery'
import ContactForm from './ContactForm';
import Homegeleery from './Homegeleery';
import Newaboutcompony from './Newaboutcompony';
import NewwhychosesUS from './NewwhychosesUS';
import Testmonial from './Testmonial';
import OurLeadership from './OurLeadership';
import Ourclints from './Ourclints';
import HoveredCards from './HoveredCards';
import AllvariousCards from './AllvariousCards';
import Homesecondslidercards from './Homesecondslidercards';
import Homesliderdata from './Homesliderdata';
import AboutCompany from './AboutCompany';
import OurCommitments from'./OurCommitments';
import GroupOfCompanies  from'./GroupOfCompanies';
import WhoweAre from './WhoweAre';
import RentarCar from'./RentarCar';
import OurTeam from'./OurTeam';
const Home = () => {
  // Scroll to the top of the page when the component mounts
  //  All the related code previous code and all other formated 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs 

  // All other formates of the code and all not required 
  // Related code also not formated and chnages and communicated
  // All the data community of the various of the sectors code a 
  // Recomanded coding formated skills also not provided in the regular


  return (
    <div>
      <MainBanner />
      <Stack />
      <Services />
      <RentarCar/>
      <NewwhychosesUS />
      <Homesliderdata />

     
      {/* <AboutCompany /> */}
      {/* <OurCommitments /> */}
      
      {/* <GroupOfCompanies /> */}
      {/* <Homegeleery /> */}
      {/* <Ourclints /> */}
      <OurTeam/>
    
      {/* <AllvariousCards /> */}
      {/* <Homesecondslidercards /> */}
      {/* <HoveredCards /> */}


    </div>
  )
}

export default Home