
import React, { useState } from "react";
import miniCar from "../img/aaaservices/qw.png";
import sedanCar from "../img/aaaservices/blue.png";
import premiumSedanCar from "../img/aaaservices/suvf.webp";
import suvCar from "../Component/si.png";
import premiumSuvCar from "../img/banner/PREMIEUM SEDAN.jpg";
import premiumSuvCar1 from "../img/aaaservices/blue.png";
import miniBus from "../Component/MINI BUS.avif";
import luxuryBus from "../img/banner/LUEXERYBUS.jpg";


const cars = [
  {
    name: "MINI",
    image: miniCar,
    features: ["AC", "Mobile Charger", "Sanitizer", "4 Seats", "GPS", "FirstAid Kit", "2 Bags"],
  },
  {
    name: "SEDAN",
    image: sedanCar,
    features: ["AC", "Mobile Charger", "Sanitizer", "4 Seats", "GPS", "FirstAid Kit", "2 Bags"],
  },

  {
    name: "SUV",
    image: suvCar,
    features: ["AC", "Mobile Charger", "Sanitizer", "4 Seats", "GPS", "FirstAid Kit", "2 Bags"],
  },
  {
    name: "PREMIUM SEDAN",
    image: premiumSedanCar,
    features: ["AC", "Mobile Charger", "Sanitizer", "4 Seats", "GPS", "FirstAid Kit", "2 Bags"],
  },

  {
    name: "PREMIUM SUV",
    image: premiumSuvCar1,
    features: ["AC", "Mobile Charger", "Sanitizer", "4 Seats", "GPS", "FirstAid Kit", "2 Bags"],
  },
];

const buses = [
  {
    name: "Mini Bus",
    image: miniBus,
    features: ["AC", "20 Seats", "GPS", "Music", "Luggage Space"],
  },
  {
    name: "Luxury Bus",
    image: luxuryBus,
    features: ["AC", "30 Seats", "Recliner Seats", "GPS", "Music", "TV"],
  },
  {
    name: "Standard Bus ",
    image: luxuryBus,
    features: ["AC", "30,45 Seats", "Recliner Seats", "GPS", "Music", "TV"],
  },
  {
    name: "Sleeper Bus ",
    image: luxuryBus,
    features: ["AC", "30,45 Seats", "Recliner Seats", "GPS", "Music", "TV"],
  },
  {
    name: "Midi Bus ",
    image: luxuryBus,
    features: ["AC", "30,45 Seats", "Recliner Seats", "GPS", "Music", "TV"],
  }


];

const CarFilter = () => {
  const [activeCategory, setActiveCategory] = useState("cars");
  const [selectedCar, setSelectedCar] = useState(cars[0]);
  const [selectedBus, setSelectedBus] = useState(buses[0]);

  return (

    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Arial, sans-serif" }}>

      {/* Category Selection */}
      <div style={{ marginBottom: "20px" }}>
        {/* <button
          style={{ padding: "10px 20px", margin: "0 10px", backgroundColor: activeCategory === "cars" ? "#ffcc00" : "#f0f0f0", cursor: "pointer", fontWeight: "bold",borderRadius:'30px' }}
          onClick={() => setActiveCategory("cars")}
        >
          Cars
        </button>
        <button
          style={{ padding: "10px 20px", margin: "0 10px", backgroundColor: activeCategory === "buses" ? "#ffcc00" : "#f0f0f0", cursor: "pointer", fontWeight: "bold",borderRadius:'30px' }}
          onClick={() => setActiveCategory("buses")}
        >
          Buses
        </button> */}

      </div>



      {/* Content Section */}
      {activeCategory === "cars" ? (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", gap: "43px", }}>
          {/* Car Filter Buttons */}
          <div style={{ display: "flex", flexDirection: "column", gap: "10px", }}>
            {cars.map((car) => (
              <button
                key={car.name}
                style={{ padding: "10px 15px", border: "none", backgroundColor: selectedCar.name === car.name ? "#ffcc00" : "#f0f0f0", cursor: "pointer", borderRadius: '18px', fontWeight: "bold" }}
                onClick={() => setSelectedCar(car)}
              >
                {car.name}
              </button>
            ))}
          </div>
          {/* Car Image and Features */}
          <div style={{ textAlign: "center" }}>
            <img src={selectedCar.image} alt={selectedCar.name} style={{ width: "324px", height: "auto", borderRadius: '30px' }} />
            <div style={{ marginTop: "20px", display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center", }}>
              {selectedCar.features.map((feature, index) => (
                <span key={index} style={{ padding: "8px 12px", backgroundColor: "#007bff", color: "#fff", borderRadius: "4px", fontSize: "14px" }}>{feature}</span>
              ))}
            </div>
          </div>
          {/* Enquiry Form */}
          <div style={{ width: "300px", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", position: "relative" }}>
            <h3 style={{ textAlign: "center", color: '#ffca00' }}>Enquiry Form</h3>
            <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <input type="text" placeholder="Your Name" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
              <input type="email" placeholder="Your Email" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
              <input type="tel" placeholder="Your Phone" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
              <textarea placeholder="Your Message" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", resize: "none" }}></textarea>
              <button type="submit" style={{ padding: "10px", backgroundColor: "#ffcc00", border: "none", borderRadius: "4px", cursor: "pointer", fontWeight: "bold" }}>Submit</button>
            </form>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", gap: "63px", backgroundColor: '#f7f7f7' }}>
          {/* Bus Filter Buttons */}
          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {buses.map((bus) => (
              <button
                key={bus.name}
                style={{ padding: "10px 15px", border: "none", backgroundColor: selectedBus.name === bus.name ? "#ffcc00" : "#f0f0f0", cursor: "pointer", borderRadius: '18px', fontWeight: "bold" }}
                onClick={() => setSelectedBus(bus)}
              >
                {bus.name}
              </button>
            ))}
          </div>
          {/* Bus Image and Features */}
          <div style={{ textAlign: "center" }}>
            <img src={selectedBus.image} alt={selectedBus.name} style={{ width: "431px", height: "auto", borderRadius: '30px' }} />
            <div style={{ marginTop: "20px", display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center" }}>
              {selectedBus.features.map((feature, index) => (
                <span key={index} style={{ padding: "8px 12px", backgroundColor: "#007bff", color: "#fff", borderRadius: "4px", fontSize: "14px" }}>{feature}</span>
              ))}
            </div>
          </div>
          {/* Enquiry Form */}
          <div style={{ width: "300px", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", position: "relative" }}>
            <h3 style={{ textAlign: "center" }}>Enquiry Form</h3>
            <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>          <input type="text" placeholder="Your Name" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
              <input type="email" placeholder="Your Email" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
              <input type="tel" placeholder="Your Phone" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
              <textarea placeholder="Your Message" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", resize: "none" }}></textarea>
              <button type="submit" style={{ padding: "10px", backgroundColor: "#ffcc00", border: "none", borderRadius: "4px", cursor: "pointer", fontWeight: "bold" }}>Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarFilter;