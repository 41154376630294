import React, { useState } from "react";
import { motion } from "framer-motion";
import aboutUsImage from "../img/aaaservices/monthly.png"; // Import the image

const AboutUs = () => {
    const [showMore, setShowMore] = useState(false);

    return (
        <section className="about-area ptb-100" style={{ padding: "0px 0" }}>
            <div className="container" >
                <div
                    className="row"
                    style={{
                        display: "flex",
                        alignItems: "flex-start", // Image fixed rahega, text badhega
                        flexWrap: "wrap",
                        marginTop: "-12px",
                    }}
                >
                    {/* Right Side: About Image (Fixed Position) */}
                    <motion.div
                        className="col-lg-6 col-md-12 text-center"
                        initial={{ x: "100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        style={{
                            position: "sticky", // Yeh Image ko fix karega
                            top: "10px", // Yeh Image ko scroll ke time fix rakhega
                        }}
                    >
                        <div>
                            <img
                                src={aboutUsImage}
                                alt="About Us"
                                className="img-fluid"
                                style={{
                                    maxWidth: "100%",
                                    height: "400px",
                                    borderRadius: "10px",
                                    display: "block",
                                    marginLeft: "auto",
                                }}
                            />
                        </div>
                    </motion.div>

                    {/* Left Side: About Content (Expanding Text) */}
                    <motion.div
                        className="col-lg-6 col-md-12"
                        initial={{ x: "-100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}

                        style={{
                            textAlign: "left",
                            maxWidth: "600px",
                            paddingLeft: "20px",
                            transition: "max-height 0.5s ease-in-out", // Smooth expansion
                            overflow: "hidden", // Extra content ko smoothly appear hone dega
                        }}
                    >
                        <h1
                            style={{
                                fontSize: "30px",
                                fontFamily: "Lato, sans-serif",
                                position: "relative",
                                display: "inline-block"
                            }}
                        >
                            Monthly  <span style={{ color: "rgb(235, 195, 101)" }}>Rental</span>
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: "-8px",
                                    left: "0",
                                    width: "50%",
                                    height: "2px",
                                    backgroundColor: "#000",
                                }}
                            />
                        </h1>

                        <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                            <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>Sri Shiva Travels</span>,
                            offer customized monthly rental car service for corporates. Our packages are
                            designed to enable companies to rent out the car for monthly basis. If your employees keep travelling
                            to different places and have delegates coming regularly, then booking a monthly car rental service
                            from Sri Shiva Travels is the best. Whether you need a luxury car or simple car, we have all under the
                            monthly car rental package,Compare the monthly car rental package with other transport companies, we guarantee that you will
                            get the best in us. The rates come in up to 2000km to 5000km and extra per hours.
                        </p>

                        {/* View More Section */}
                        <div style={{ maxHeight: showMore ? "1000px" : "0px", overflow: "hidden", transition: "max-height 0.5s ease-in-out" }}>
                            <h5
                                style={{
                                    fontSize: "20px",
                                    fontFamily: "Lato, sans-serif",
                                    position: "relative",
                                    display: "inline-block"
                                }}
                            >
                                Professional <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "-8px",
                                        left: "0",
                                        width: "50%",
                                        height: "2px",
                                        backgroundColor: "#000",
                                    }}
                                />
                            </h5>
                            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                                You can connect with us, understand the package and book it accordingly for long term car rental
                                service. We got fleet of cars for employees working in different positions. So you can book accordingly
                                and help employee commute easily.
                                <div style={{ fontFamily: "Arial, sans-serif", padding: "20px", fontSize: '16px' }}>
                                    <h2 style={{ color: "rgb(235, 195, 101)", textAlign: "left", fontSize: "16px", marginBottom: "15px" }}>
                                        Rent us for a Month?
                                    </h2>
                                    <ul style={{ listStyleType: "none", padding: "0" }}>
                                        <li style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                                            <strong style={{ color: "rgb(235, 195, 101)" }}>Why rent us for a month? </strong> <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>Cost effective monthly package
                                                ∙Multiple Fleet options
                                                ∙Premium customer service </p>
                                        </li>
                                        <li style={{ fontSize: "16px", marginBottom: "10px" }}>
                                            <strong style={{ color: "rgb(235, 195, 101)" }}>Total Flexibility :</strong><p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}> When you book our monthly car rental service, you get complete flexibility. Right from booking to
                                                choosing the package and car, you can book as per your corporate need. </p>
                                        </li>
                                        <li style={{ fontSize: "18px", marginBottom: "10px" }}>
                                            <strong style={{ color: "rgb(235, 195, 101)" }}>Vehicle Choice </strong><p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}> Whether you want to choose the vehicle for top executives or employees, we have fleet of cars to
                                                choose. Book the car according to your business need. You can book us in advance for the monthly
                                                care rental purpose and get the best package with amazing fleet of car options. </p>
                                        </li>
                                    </ul>
                                </div>
                            </p>
                            
                        </div>

                        {/* View More Button */}
                        <button
                            onClick={() => setShowMore(!showMore)}
                            style={{
                                marginTop: "10px",
                                backgroundColor: "#ebc365",
                                border: "none",
                                padding: "10px 20px",
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#000",
                                borderRadius: "5px",
                                transition: "background 0.3s ease-in-out",
                            }}
                        >
                            {showMore ? "View Less" : "View More"}
                        </button>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;