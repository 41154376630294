import React, { useState } from "react";
import { motion } from "framer-motion";
import aboutUsImage from "../img/aaaservices/aIRPORTJ.jpg"; // Import the image

const AboutUs = () => {
    const [showMore, setShowMore] = useState(false);

    return (
        <section className="about-area ptb-100" style={{ padding: "0px 0" }}>
            <div className="container" >
                <div
                    className="row"
                    style={{
                        display: "flex",
                        alignItems: "flex-start", // Image fixed rahega, text badhega
                        flexWrap: "wrap",
                        marginTop: "-12px",
                    }}
                >
                    {/* Right Side: About Image (Fixed Position) */}
                    <motion.div
                        className="col-lg-6 col-md-12 text-center"
                        initial={{ x: "100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}
                        style={{
                            position: "sticky", // Yeh Image ko fix karega
                            top: "10px", // Yeh Image ko scroll ke time fix rakhega
                        }}
                    >
                        <div>
                            <img
                                src={aboutUsImage}
                                alt="About Us"
                                className="img-fluid"
                                style={{
                                    maxWidth: "100%",
                                    height: "400px",
                                    borderRadius: "10px",
                                    display: "block",
                                    marginLeft: "auto",
                                }}
                            />
                        </div>
                    </motion.div>

                    {/* Left Side: About Content (Expanding Text) */}
                    <motion.div
                        className="col-lg-6 col-md-12"
                        initial={{ x: "-100vw", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, ease: "easeOut" }}

                        style={{
                            textAlign: "left",
                            maxWidth: "600px",
                            paddingLeft: "20px",
                            transition: "max-height 0.5s ease-in-out", // Smooth expansion
                            overflow: "hidden", // Extra content ko smoothly appear hone dega
                        }}
                    >
                        <h1
                            style={{
                                fontSize: "30px",
                                fontFamily: "Lato, sans-serif",
                                position: "relative",
                                display: "inline-block"
                            }}
                        >
                            Airport <span style={{ color: "rgb(235, 195, 101)" }}>Transportation</span>
                            <span
                                style={{
                                    position: "absolute",
                                    bottom: "-8px",
                                    left: "0",
                                    width: "50%",
                                    height: "2px",
                                    backgroundColor: "#000",
                                }}
                            />
                        </h1>

                        <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                            <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>Sri Shiva Travels</span>,
                            Can be no better antitode to your aliments for reaching destination We are a full-service 
transportation company with one of the most extensive fleets of luxury sedans, SUVs and limos. 
Known as one of the top service providers in the region, we have been providing clients with an 
unrivalled personalized chauffeur experience.Unsurpassed reliability and attention to detail are the values that infuse everything we do. We are 

                        </p>

                        {/* View More Section */}
                        <div style={{ maxHeight: showMore ? "1000px" : "0px", overflow: "hidden", transition: "max-height 0.5s ease-in-out" }}>
                            <h5
                                style={{
                                    fontSize: "20px",
                                    fontFamily: "Lato, sans-serif",
                                    position: "relative",
                                    display: "inline-block"
                                }}
                            >
                                Professional <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
                                <span
                                    style={{
                                        position: "absolute",
                                        bottom: "-8px",
                                        left: "0",
                                        width: "50%",
                                        height: "2px",
                                        backgroundColor: "#000",
                                    }}
                                />
                            </h5>
                            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                            We recruit professionals that have passed our strict requirements so we can provide consistent, 
outstanding service for our clientele. Our well-mannered, discreet, and knowledgeable chauffeurs will 
create the perfect atmosphere tailored to your needs as you arrive to your destination safely as well 
as in style and comfort. 
                            </p>
                            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
                            committed to delivering only the utmost in class and distinction. This dedication is based on our belief 
that the primary focus of our clients should be to sit back and enjoy a ride that will leave memories 
lasting for a lifetime. We have more than 20,000 airport transfers done annually  

                            </p>
                        </div>

                        {/* View More Button */}
                        <button
                            onClick={() => setShowMore(!showMore)}
                            style={{
                                marginTop: "10px",
                                backgroundColor: "#ebc365",
                                border: "none",
                                padding: "10px 20px",
                                cursor: "pointer",
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#000",
                                borderRadius: "5px",
                                transition: "background 0.3s ease-in-out",
                            }}
                        >
                            {showMore ? "View Less" : "View More"}
                        </button>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;