// import React, { useState } from 'react';
// import emailjs from 'emailjs-com'; // Import EmailJS
// import customersupport from '../img/services-image/customer-support.jpeg'
// const ContactForm = () => {
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     emailjs.sendForm('service_qa3zbmm', 'template_cezc1ah', e.target, 'BpJS--NtwuBSXs9NC')
//       .then((result) => {
//         console.log(result.text);
//         alert('Success! Your message has been sent.'); // Success alert
//         setIsSubmitting(false);
//       }, (error) => {
//         console.log(error.text);
//         alert('Error! Your message could not be sent. Please try again later.'); // Failure alert All the connected formates
//         setIsSubmitting(false);
//       });
//   };

//   return (
//     <>
//       {/* Section: Get in Touch */}
//       {/*  */}
//       {/* All othe */}
//       {/* Real time experiances  */}
//       {/* All other documentations and another required ContactFormCode.js,*/}
//       <section aria-label="section" className="no-bottom">
//         <div className="container-fluid">
//           <div className="row p-3-vh">
//             <div className="col-12 centered my-o">
//             <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato,sans,serif', fontSize: '48px', }}>
//           <div class="text-center"> <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif', textAlign: 'center' }}> Get In Touch <span style={{ color: "rgb(235, 195, 101)" }}>With Us</span>
//           </h1></div>
//           <div
//             style={{
//               width: '200px', // Adjust the width of the line
//               height: '2px', // Thickness of the line
//               backgroundColor: '#000', // Black color
//               margin: '10px auto 0', // Center alignment and spacing
//               marginTop: '-20px'
//             }}
//           ></div>
//         </div>


//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Section: Contact */}
//       <section aria-label="contact" style={{ padding: '20px' }}>
//         <div className="container-fluid">
//           <div className="row p-3-vh">

//             {/* Contact Information */}
//             <div className="col-md-6">
//               <section aria-label="map" className="no-bottom">
//                 {/* <div className="container-fluid"> */}
//                 <div className="row p-3-vh">
//                   <div className="col-md-12">
//                     <div>
//                       {/* <img src={customersupport} className="imgbg-col" alt="imghome" style={{ height: '350px' }} /> */}
//                       <iframe
//                         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7610.7433924449315!2d78.38240638937562!3d17.489761383659552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb918dab342375%3A0x180a04af0c47f594!2sManjeera%20Trinity%20Corporate!5e0!3m2!1sen!2sin!4v1723212055136!5m2!1sen!2sin"
//                         width="100%"
//                         height="450"
//                         style={{ border: 0 }}
//                         allowFullScreen=""
//                         loading="lazy"
//                         referrerPolicy="no-referrer-when-downgrade"
//                       ></iframe>
//                     </div>
//                   </div>
//                   {/* </div> */}
//                 </div>
//               </section>
//             </div>

//             {/* Contact Form */}
//             <div className="col-md-6">
//               <form id="form-contact1" className="autoheight" onSubmit={sendEmail}>
//                 <div className="form-group user-name">
//                   <input type="text" name="name" className="form-control" required placeholder="Your Name" />
//                 </div>
//                 <div className="form-group user-email">
//                   <input type="email" name="email" className="form-control" required placeholder="Your Email" />
//                 </div>
//                 <div className="form-group user-phone">
//                   <input type="tel" name="phone" className="form-control" required placeholder="Your Phone Number" maxLength="10" pattern="\d{10}" title="Please enter exactly 10 digits" />
//                 </div>
//                 <div className="form-group user-message">
//                   <textarea name="message" className="form-control" required placeholder="Your Message"></textarea>
//                 </div>
//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                   <button
//                     type="submit"
//                     id="send-contact-1"
//                     className="btn-contact"
//                     disabled={isSubmitting}
//                   >
//                     Send Now
//                   </button>
//                 </div>

//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default ContactForm;



// import React from "react";

// import { color } from "framer-motion";

// const GetInTouch = () => {
//   return (
//     <div style={styles.container}>
//       {/* Left Section - Contact Info */}
//       <div style={styles.leftSection}>
//         <h2 style={styles.heading}>GET IN TOUCH</h2>

//         <div style={styles.infoBox}>

//           <div>
//             <h4 style={styles.infoTitle}>Call Now</h4>
//             <p style={styles.infoText}>+91 9676222231</p>
//           </div>
//         </div>

//         <div style={styles.infoBox}>

//           <div>
//             <h4 style={styles.infoTitle}>E-mail Address</h4>
//             <p style={styles.infoText}>
//               sandeep@srishivatravels.com <br />
//               jai@srishivatravels.com</p>
//           </div>
//         </div>

//         <div style={styles.infoBox}>

//           <div>
//             <h4 style={styles.infoTitle}>Address</h4>
//             <p style={styles.infoText}>
//               SRI SHIVA TRAVELS
//               Plot No.81 MIG IV Phase, APHB Colony Serilingamplly,
//               KV Rangareddy Gachibowli, Hyderabad - 500 032, T.G.Office No. 0404971039
//               </p>
//             <p style={{ fontWeight: "bold",color:'black' }}>Sandeep : 9676222231||

//               Jai MK : 9642944442 </p>
//           </div>
//         </div>
//       </div>

//       {/* Right Section - Contact Form */}
//       <div style={styles.rightSection}>
//         <form style={styles.form}>
//           <div style={styles.row}>
//             <input type="text" placeholder="Name" style={styles.input} />
//             <input type="email" placeholder="E-Mail" style={styles.input} />
//           </div>

//           <div style={styles.row}>
//             <input type="text" placeholder="Phone Number" style={styles.input} />
//             <input type="text" placeholder="Subject" style={styles.input} />
//           </div>

//           <textarea placeholder="Your Message Here" style={styles.textarea}></textarea>

//           <button style={styles.button}>SUBMIT NOW ➜</button>
//         </form>
//       </div>

//     </div>
//   );
// };

// // Internal CSS Styles
// const styles = {
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//     alignItems: "stretch",
//     padding: "40px 20px",
//     gap: "20px",
//     maxWidth: "1100px",
//     margin: "0 auto",
//   },
//   leftSection: {
//     flex: "1",
//     minWidth: "300px",
//     backgroundColor: "rgb(235, 195, 101)",
//     padding: "30px",
//     borderRadius: "10px",
//     color: "white",
//   },
//   rightSection: {
//     flex: "1.5",
//     minWidth: "300px",
//     backgroundColor: "#333",
//     padding: "30px",
//     borderRadius: "10px",
//     color: "white",
//   },
//   heading: {
//     fontSize: "24px",
//     fontWeight: "bold",
//     marginBottom: "20px",
//     textAlign: "center",
//     textAlign: 'left',
//     fontFamily: "Roboto, sans-serif",

//   },
//   infoBox: {
//     display: "flex",
//     alignItems: "center",
//     gap: "10px",
//     marginBottom: "15px",

//   },
//   icon: {
//     fontSize: "24px",
//   },
//   infoTitle: {
//     fontSize: "18px",
//     fontWeight: "bold",
//     textAlign: 'left',
//     fontFamily: "Roboto, sans-serif",

//   },
//   infoText: {
//     fontSize: "14px",
//     margin: "0",
//     textAlign: 'left',

//     color: "black"

//   },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     gap: "15px",
//   },
//   row: {
//     display: "flex",
//     gap: "10px",
//   },
//   input: {
//     flex: "1",
//     padding: "10px",
//     fontSize: "14px",
//     borderRadius: "5px",
//     border: "none",
//     outline: "none",
//   },
//   textarea: {
//     width: "100%",
//     height: "100px",
//     padding: "10px",
//     fontSize: "14px",
//     borderRadius: "5px",
//     border: "none",
//     outline: "none",
//   },
//   button: {
//     padding: "12px",
//     fontSize: "16px",
//     backgroundColor: "rgb(235, 195, 101)",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//     color: "black",
//     fontWeight: "bold",
//   },
// };

// // Export the component
// export default GetInTouch;



import React from "react";

const GetInTouch = () => {
  return (
    <>
      {/* Contact Section */}
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <h2 style={{
          fontSize: "48px",
          fontWeight: "700",
          fontFamily: "Lato, sans-serif",
          textAlign: "center"
        }}>
          <span style={{ color: "black" }}>Get In</span>
          <span style={{ color: "rgb(235, 195, 101)", marginLeft: "5px" }}>Touch</span>
        </h2>
        <div style={{
          width: "100px",
          height: "3px",
          backgroundColor: "rgb(0, 0, 0)",
          margin: "0px auto",
          marginTop: "-34px"
        }}></div>
      </div>

      <div style={styles.container}>
        {/* Left Section - Contact Info */}
        <div style={styles.leftSection}>


          <div style={styles.infoBox}>
            <div>
              <h4 style={styles.infoTitle}>Call Now</h4>
              <p style={styles.infoText}>+91 9676222231</p>
            </div>
          </div>

          <div style={styles.infoBox}>
            <div>
              <h4 style={styles.infoTitle}>E-mail Address</h4>
              <p style={styles.infoText}>
                sandeep@srishivatravels.com <br />
                jai@srishivatravels.com
              </p>
            </div>
          </div>

          <div style={styles.infoBox}>
            <div>
              <h4 style={styles.infoTitle}>Address</h4>
              <p style={styles.infoText}>
                SRI SHIVA TRAVELS
                House No. 2-48/9/401,Nova
                Jyot Apartment, Telecom Nagar, Urdu University, Gachibowli, Serilingampally, Rangareddy, Telangana, 500032


              </p>
              <p style={{ fontWeight: "", color: "black", textAlign: 'left' }}>
                Sandeep: 9676222231 <br /> Jai MK: 9642944442
              </p>
            </div>
          </div>
        </div>

        {/* Right Section - Contact Form */}
        <div style={styles.rightSection}>
          <form style={styles.form}>
            <div style={styles.row}>
              <input type="text" placeholder="Name" style={styles.input} />
              <input type="email" placeholder="E-Mail" style={styles.input} />
            </div>

            <div style={styles.row}>
              <input type="text" placeholder="Phone Number" style={styles.input} />
              <input type="text" placeholder="Subject" style={styles.input} />
            </div>

            <textarea placeholder="Your Message Here" style={styles.textarea}></textarea>

            <button style={styles.button}>SUBMIT NOW ➜</button>
          </form>
        </div>
      </div>

      {/* Full-Screen Map Section */}
      <div style={styles.mapContainer}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5092463136757!2d78.36353327435762!3d17.43532368345996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93ed7e297671%3A0x93e7da6ec35cd19!2sNova%20Jyot%20Residency!5e0!3m2!1sen!2sus!4v1743598346267!5m2!1sen!2sus" style={styles.map} width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
        {/* <iframe
          title="Location Map"
          src="https://maps-api-ssl.google.com/maps?hl=en-US&ll=17.446215,78.358952&output=embed&q=MIG-82,+APHB+Colony,+JV+Colony,+Chhota+Anjaiah+Nagar,+Gachibowli,+Hyderabad,+Telangana+500032,+India+(APCL+GH)&z=18"
        
          allowFullScreen
          loading="lazy"
        ></iframe> */}
      </div>
    </>
  );
};


// Internal CSS Styles
const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "stretch",
    padding: "40px 20px",
    gap: "20px",
    maxWidth: "1100px",
    margin: "0 auto",
    marginTop: '-35px',
  },
  leftSection: {
    flex: "1",
    minWidth: "300px",
    backgroundColor: "rgb(235, 195, 101)",
    padding: "30px",
    borderRadius: "10px",
    color: "white",
  },
  rightSection: {
    flex: "1.5",
    minWidth: "300px",
    backgroundColor: "#333",
    padding: "30px",
    borderRadius: "10px",
    color: "white",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    marginTop: '20px',
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "15px",
  },
  infoTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Roboto, sans-serif",
  },
  infoText: {
    fontSize: "14px",
    margin: "0",
    textAlign: "left",
    color: "black",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  row: {
    display: "flex",
    flexDirection: "column", // Ensures responsiveness
    gap: "10px",
  },
  input: {
    width: "100%", // Full width for responsiveness
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    border: "none",
    outline: "none",
  },
  textarea: {
    width: "100%",
    height: "100px",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    border: "none",
    outline: "none",
  },
  button: {
    padding: "12px",
    fontSize: "16px",
    backgroundColor: "rgb(235, 195, 101)",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    color: "black",
    fontWeight: "bold",
    width: "166px",
    textAlign: "center"
  },
  mapContainer: {
    width: "100vw",
    height: "60vh",
    marginTop: "20px",
    borderTop: "15px solid rgb(235, 195, 101)", // Thick yellow top border
  },

  map: {
    width: "100%",
    height: "100%",
    border: "none",
  },
};

// Export the component
export default GetInTouch;

