// import React,{useEffect} from 'react'
// import bannerImage from '../img/banner/cm-img.png';
// import abouthome from '../img/serviceses/RESIDENTIAL COLLAGE1.png'
// import ServicesCards from './ServicesCards';
// const WebApplications = () => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//       }, []); // Empty dependency array ensures it only runs once when the component mounts

//   return (
//     <div>
//          <div>
//         <div className="hero-area overly-style-1 opacity-point-4">
//       {/* Replace video with image */}
//       <img 
//         className="banner-image" 
//         src={bannerImage} 
//         alt="About AA Applications Banner" 
//         style={{ width: '100%', height: 'auto' }} 
//       />

//       <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
//         <h1 style={{color:"#fff",fontSize:'48px'}}>COMMERICAL INTERIORS</h1>
//         <div className="bread-subtitle" style={{marginTop:'0px'}}> Transforming Commercial Spaces with Virtual Vision </div>
//         {/* Uncomment these if needed */}
//         {/* <h1 className="title" data-aos="fade-up" data-aos-delay="200" style={{ color: '#fff' }}>Co Working</h1> */}
//         {/* <p data-aos="fade-up" data-aos-delay="300">Quisque at justo sagittis, semper lacus a, iaculis tellus. Fusce tempor, leo vel iaculis aliquet,</p> */}
//         {/* <a href="project.html" className="btn btn-primary btn-hover-dark" data-aos="fade-up" data-aos-delay="400">VIEW PROJECT</a> */}
//       </div>
//     </div>
//     </div>
//         <section className="no-top no-bottom bggray" aria-label="abouthome">
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-md-6 p-0">
//         <img src={abouthome}className="imgbg-col" alt="imghome" />
//           </div>
//           <div className="col-md-6 centered">
//             <div className="detailcontent">
//               <div className="subheading" style={{ color: '#1c1d1d', fontSize: '30px', textAlign: 'center', marginBottom: '11px' }}>
//                 About <span style={{ color: '#1c1d1d', fontSize: '30px' }}>COMMERICAL INTERIORS</span>
//               </div>
//               {/* <div className="heading">Best Interior is Our Passion</div> */}
//               <div className="textdetail">
//               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//               <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span>   interiors, we specialize in creating functional and aesthetically pleasing commercial interiors that enhance productivity and reflect your brand’s identity. Our expertise spans office spaces, retail stores, restaurants, and more, with designs that prioritize both efficiency and visual appeal. We integrate advanced technology, ergonomic furniture, and innovative layouts to craft environments where employees thrive and customers feel welcome. Whether 

//                </p>
//                <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                 </p>
//                <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span> it’s a modern office with flexible workstations or a chic retail space with engaging displays, our tailored solutions ensure your commercial interiors are both practical and inspiring, perfectly aligned with your business goals.  </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//     <ServicesCards/>
//     </div>
//   )
// }

// export default WebApplications








// import React,{useEffect} from 'react'
// import bannerImage from '../img/banner/gallery.jpg';
// import abouthome from '../img/serviceses/RESIDENTIAL COLLAGE1.png'
// import ServicesCards from './ServicesCards';
// const PCMobileApplications = () => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//       }, []); // Empty dependency array ensures it only runs once when the component mounts

//   return (
//     <div>
//     <div>
//    <div className="hero-area overly-style-1 opacity-point-4">
//  {/* Replace video with image */}
//  <img 
//    className="banner-image" 
//    src={bannerImage} 
//    alt="About AA Applications Banner" 
//    style={{ width: '100%', height: 'auto' }} 
//  />

//  <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
//  <h1 style={{ color: "#000", fontSize: '48px', }}>
//               WEB <span style={{ color: "rgb(73 175 201);" }}>INTERIORS</span>
//             </h1>
//    {/* Uncomment these if needed */}
//    {/* <h1 className="title" data-aos="fade-up" data-aos-delay="200" style={{ color: '#fff' }}>Co Working</h1> */}
//    {/* <p data-aos="fade-up" data-aos-delay="300">Quisque at justo sagittis, semper lacus a, iaculis tellus. Fusce tempor, leo vel iaculis aliquet,</p> */}
//    {/* <a href="project.html" className="btn btn-primary btn-hover-dark" data-aos="fade-up" data-aos-delay="400">VIEW PROJECT</a> */}
//  </div>
// </div>
// </div>
//    <section className="no-top no-bottom bggray" aria-label="abouthome">
//  <div className="container-fluid">
//    <div className="row">
//      <div className="col-md-6 p-0">
//    <img src={abouthome}className="imgbg-col" alt="imghome" />
//      </div>
//      <div className="col-md-6 centered">
//        <div className="detailcontent">
//          <div className="subheading" style={{ color: '#1c1d1d', fontSize: '48px', textAlign: 'center', marginBottom: '11px' }}>
//           WEB <span style={{ color: '#1c1d1d', fontSize: '48px' }}>DESIGN</span>
//          </div>
//          {/* <div className="heading">Best Interior is Our Passion</div> */}
//          <div className="textdetail">
//          <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//          <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span> provides organizations of all sizes with the best, most trustworthy IT solutions. In the ever-evolving technology market, we believe that companies need reliable allies who can guide them through the challenges that accompany technological growth. We provide IT Support, Services and development services - to name a few. Contact us today to find out more.
//           </p>
//           <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//           "Providing Automated Products for  Enterprises"
//           In order for enterprises to be fully compliant with regulations, enterprises need to improve business processes, information flow within and across enterprises and provide secure information delivery. </p>
//           <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//           <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span> provides a customer-focused products specialized in business solutions using advanced technologies.  </p>
//          </div>
//        </div>
//      </div>
//    </div>
//  </div>
// </section>
// <ServicesCards/>
// </div>
//   )
// }

// export default PCMobileApplications





// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShuttleVan, faBus, faCar, faPlaneDeparture, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
// import FilterEmploye from './FilterEmploye';
// import FilterEvent from './FilterEvent';
// import FilterAirport from './FilterAirport';
// import FilterCorporate from './FilterCorporate';
// import FullStack from './FullStack';
// import FilterMonthly from './FilterMonthly';

// // Import banner images
// import DefaultBanner from '../img/aaaservices/employe.jpg';
// import MiniBanner from '../img/aaaservices/employe.jpg';
// import LargeBanner from '../img/aaaservices/event.png';
// import MediumBanner from '../img/aaaservices/aIRPORTJ.jpg';
// import CarvansBanner from '../img/aaaservices/co.jpg';
// import FiltterBanner from '../img/aaaservices/monthly.png';


// const WebApplications = () => {
//   const [selectedBanner, setSelectedBanner] = useState(DefaultBanner);
//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const bannerImages = {
//     COMMERCIAL_INTERIORS: MiniBanner,
//     HOSPITALITY_INTERIORS: LargeBanner,
//     SHOWCASE_INTERIORS: MediumBanner,
//     OUTDOOR_INTERIORS: CarvansBanner,
//     SHOWCASE_INTERIORSsss: FiltterBanner
//   };

//   const handleFilterClick = (type) => {
//     setSelectedFilter(type);
//     setSelectedBanner(bannerImages[type] || DefaultBanner);
//   };

//   useEffect(() => {
//     console.log("Banner changed:", selectedBanner);
//   }, [selectedBanner]);

//   const renderContent = () => {
//     switch (selectedFilter) {
//       case 'COMMERCIAL_INTERIORS':
//         return <FilterEmploye />;
//       case 'HOSPITALITY_INTERIORS':
//         return <FilterEvent />;
//       case 'SHOWCASE_INTERIORS':
//         return <FilterAirport />;
//       case 'OUTDOOR_INTERIORS':
//         return <FilterCorporate />;

//       case 'SHOWCASE_INTERIORSsss':
//         return <FilterMonthly />;

//       default:
//         return <FilterEmploye />;
//     }
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3, // Default: Show 3 slides
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024, // For tablets and smaller laptops
//         settings: {
//           slidesToShow: 2, // Show 2 slides
//         },
//       },
//       {
//         breakpoint: 768, // For mobile devices
//         settings: {
//           slidesToShow: 1, // Show 1 slide
//         },
//       },
//     ],
//   };

//   return (
//     <div>
//       {/* Dynamic Banner */}
//       <div
//         key={selectedBanner}
//         style={{
//           width: '100%',
//           height: '500px',
//           marginTop: '92px',
//           backgroundImage: `url(${selectedBanner})`,
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//         }}
//       ></div>

//       {/* Stack Component for Every Banner */}
//       {/* <FullStack selectedFilter={selectedFilter} /> */}

//       {/* Filter Slider */}
//       <div
//         className="icon-slider-wrapper"
//         style={{
//           position: 'sticky',
//           top: '96px',
//           backgroundColor: '#ffff',
//           zIndex: 997,
//           boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//           height: '96px',
//           width: '100%',
//           // marginTop:'33px',
//         }}
//       >
//         <Slider {...settings}>
//           {[
//             { type: 'COMMERCIAL_INTERIORS', icon: faShuttleVan, label: 'Employee Transportation' }, // Shuttle van for employee transport  
//             { type: 'HOSPITALITY_INTERIORS', icon: faBus, label: 'Event Transportation' }, // Bus for event transport  
//             { type: 'OUTDOOR_INTERIORS', icon: faCar, label: 'Corporate Car' }, // Car for corporate use  
//             { type: 'SHOWCASE_INTERIORS', icon: faPlaneDeparture, label: 'Airport Transfer' }, // Airplane departure for airport transfers  
//             { type: 'SHOWCASE_INTERIORSsss', icon: faCalendarCheck, label: 'Monthly Rental' }, // Calendar for monthly rental  

//           ].map((item) => (
//             <div key={item.type} style={{ textAlign: 'center' }}>
//               <a
//                 className="filter"
//                 onClick={() => handleFilterClick(item.type)}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '33px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <FontAwesomeIcon icon={item.icon} style={{ marginBottom: '5px', fontSize: '24px' }} />
//                 <p>{item.label}</p>
//               </a>
//             </div>
//           ))}
//         </Slider>
//       </div>

//       {/* Dynamic Content Section */}
//       <div
//         className="dynamic-content-section"
//         style={{

//           background: 'rgb(242 242 242)',
//           borderRadius: '10px',

//           // marginTop: '20px',
//         }}
//       >
//         {renderContent()}
//       </div>
//     </div>
//   );
// };

// export default WebApplications;



import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuttleVan, faBus, faCar, faPlaneDeparture, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import FilterEmploye from './FilterEmploye';
import FilterEvent from './FilterEvent';
import FilterAirport from './FilterAirport';
import FilterCorporate from './FilterCorporate';
import FilterMonthly from './FilterMonthly';

// Import banner images
import DefaultBanner from '../img/aaaservices/employe.jpg';
import MiniBanner from '../img/aaaservices/employe.jpg';
import LargeBanner from '../img/aaaservices/event.png';
import MediumBanner from '../img/aaaservices/aIRPORTJ.jpg';
import CarvansBanner from '../img/aaaservices/co.jpg';
import FiltterBanner from '../img/aaaservices/monthly.png';

const WebApplications = () => {
  const [selectedBanner, setSelectedBanner] = useState(DefaultBanner);
  const [selectedFilter, setSelectedFilter] = useState('COMMERCIAL_INTERIORS');

  const bannerImages = {
    COMMERCIAL_INTERIORS: MiniBanner,
    HOSPITALITY_INTERIORS: LargeBanner,
    SHOWCASE_INTERIORS: MediumBanner,
    OUTDOOR_INTERIORS: CarvansBanner,
    SHOWCASE_INTERIORSsss: FiltterBanner
  };

  const handleFilterClick = (type) => {
    setSelectedFilter(type);
    setSelectedBanner(bannerImages[type] || DefaultBanner);
  };

  useEffect(() => {
    console.log("Banner changed:", selectedBanner);
  }, [selectedBanner]);

  const renderContent = () => {
    switch (selectedFilter) {
      case 'COMMERCIAL_INTERIORS':
        return <FilterEmploye />;
      case 'HOSPITALITY_INTERIORS':
        return <FilterEvent />;
      case 'SHOWCASE_INTERIORS':
        return <FilterAirport />;
      case 'OUTDOOR_INTERIORS':
        return <FilterCorporate />;
      case 'SHOWCASE_INTERIORSsss':
        return <FilterMonthly />;
      default:
        return <FilterEmploye />;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div>
      {/* Dynamic Banner */}
      <div className='danmic-bainner'
        key={selectedBanner}
        style={{
          width: '100%',
          height: '500px',
          marginTop: '92px',
          backgroundImage: `url(${selectedBanner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>

      {/* Filter Slider */}
      <div
        className="icon-slider-wrapper"
        style={{
          position: 'sticky',
          top: '96px',
          backgroundColor: '#fff',
          zIndex: 997,
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          height: '96px',
          width: '100%',
        }}
      >
        <Slider {...settings}>
          {[
            { type: 'COMMERCIAL_INTERIORS', icon: faShuttleVan, label: 'Employee Transportation' },
            { type: 'HOSPITALITY_INTERIORS', icon: faBus, label: 'Events Transportation' },
            { type: 'OUTDOOR_INTERIORS', icon: faCar, label: 'Corporate Car' },
            { type: 'SHOWCASE_INTERIORS', icon: faPlaneDeparture, label: 'Airport Transfer' },
            { type: 'SHOWCASE_INTERIORSsss', icon: faCalendarCheck, label: 'Monthly Rental' },
          ].map((item) => (
            <div key={item.type} style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => handleFilterClick(item.type)}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                  color: selectedFilter === item.type ? 'black' : 'rgb(235, 195, 101)', // Yellow to Black on Click
                  transition: 'color 0.3s ease',
                }}
              >
                <FontAwesomeIcon 
                  icon={item.icon} 
                  style={{ 
                    marginBottom: '5px', 
                    fontSize: '24px',
                    color: selectedFilter === item.type ? 'black' : 'rgb(235, 195, 101)', // Icon color change on click
                    transition: 'color 0.3s ease'
                  }} 
                />
                <p style={{ fontWeight: selectedFilter === item.type ? 'bold' : 'normal' }}>
                  {item.label}
                </p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      {/* Dynamic Content Section */}
      <div
        className="dynamic-content-section"
        style={{
          background: 'rgb(242 242 242)',
          borderRadius: '10px',
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};

export default WebApplications;
