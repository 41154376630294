import React, { useState } from "react";
import luxuryBus from "../img/aaaservices/carvans1.jpg";
import caravanBus from "../img/aaaservices/bli.png";
import sleeperCoach from "../img/aaaservices/bus1s.png";
import volvoBus from "../img/aaaservices/carvans4.webp";
import doubleDecker from "../img/aaaservices/ca.png";

const buses = [
  {
    name: "Luxury Tourist Bus",
    image: luxuryBus,
    features: ["AC", "40 Recliner Seats", "GPS", "Music System", "TV", "Mini Fridge"],
  },
  {
    name: "Caravan Tourist Bus",
    image: caravanBus,
    features: ["AC", "40 Recliner Seats", "GPS", "Music System", "TV", "Mini Fridge"],
  },
  {
    name: "Sleeper Coach",
    image: sleeperCoach,
    features: ["AC", "40 Recliner Seats", "GPS", "Music System", "TV", "Mini Fridge"],
  },
  {
    name: "Volvo Tourist Bus",
    image: volvoBus,
    features: ["AC", "40 Recliner Seats", "GPS", "Music System", "TV", "Mini Fridge"],
  },
  {
    name: "Double Decker Tourist Bus",
    image: doubleDecker,
    features: ["AC", "40 Recliner Seats", "GPS", "Music System", "TV", "Mini Fridge"],
  },
];

const BusFilter = () => {
  const [selectedBus, setSelectedBus] = useState(buses[0]);

  return (
    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Arial, sans-serif" }}>
      {/* Bus Section */}
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", gap: "16px", }}>
        
        {/* Bus Filter Buttons */}
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {buses.map((bus) => (
            <button
              key={bus.name}
              style={{
                padding: "10px 15px",
                border: "none",
                backgroundColor: selectedBus.name === bus.name ? "#ffcc00" : "#f0f0f0",
                cursor: "pointer",
                borderRadius: "18px",
                fontWeight: "bold",
              }}
              onClick={() => setSelectedBus(bus)}
            >
              {bus.name}
            </button>
          ))}
        </div>

        {/* Bus Image and Features */}
        <div style={{ textAlign: "center" }}>
          <img src={selectedBus.image} alt={selectedBus.name} style={{ width: "324px", height: "auto", borderRadius: "30px" }} />
          <div style={{ marginTop: "20px", display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center" }}>
            {selectedBus.features.map((feature, index) => (
              <span key={index} style={{ padding: "8px 12px", backgroundColor: "#007bff", color: "#fff", borderRadius: "4px", fontSize: "14px" }}>
                {feature}
              </span>
            ))}
          </div>
        </div>

        {/* Enquiry Form */}
        <div style={{ width: "300px", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", position: "relative" }}>
          <h3 style={{ textAlign: "center",color:'#ffca00' }}>Enquiry Form</h3>
          <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <input type="text" placeholder="Your Name" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <input type="email" placeholder="Your Email" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <input type="tel" placeholder="Your Phone" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <textarea placeholder="Your Message" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", resize: "none" }}></textarea>
            <button type="submit" style={{ padding: "10px", backgroundColor: "#ffcc00", border: "none", borderRadius: "4px", cursor: "pointer", fontWeight: "bold" }}>Submit</button>
          </form>
        </div>

      </div>
    </div>
  );
};

export default BusFilter;
