// import React from 'react';

// const OurCommitments = () => {
//   return (
//     <section id="commitment" style={{ paddingTop: '40px' }}>
//       <div className="container">
//         <div className="row">
//           <section id="cards-containers" style={{ textAlign: 'center' }}>
//             <div className="container">
//             <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
//                       Our <span style={{ color: "#3395AD" }}>Commitments</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//             </div>
//             {/* Our Vision Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                     <i className="fa fa-crosshairs" style={{ color: '#363435' }}></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Vision</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Vision</h4>
//                   <p style={{ color: 'white', fontSize: '13px' }}>
//                     Our vision is to be the premier provider of integrated technology solutions, driving innovation and transformation for businesses worldwide. We aspire to create a future where seamless technology integration accelerates business success and unlocks limitless potential.
//                   </p>
//                 </div>
//               </div>
//             </div>

//             {/* Our Mission Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                     <i className="fa fa-bullseye" style={{ color: '#363435' }}></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Mission</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Mission</h4>
//                   <p style={{ color: 'white', fontSize: '12px' }}>
//                     our mission is to empower organizations by delivering customized, cutting-edge technology solutions that drive growth, enhance efficiency, and foster innovation. We are committed to understanding the unique needs of our clients and providing them with the tools and support they need to thrive in a rapidly evolving digital landscape. </p>
//                 </div>
//               </div>
//             </div>

//             {/* Our Values Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                   <i className="fa fa-heart" style={{ color: '#363435' }}></i>

//                   </div>

//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Values</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Values</h4>
//                   <p style={{ color: 'white', fontSize: '14px' }}>
//                     Innovation<br />
//                     Integrity<br />
//                     Collaboration<br />
//                     Customer-Centricity<br />
//                     Excellence<br />
//                     Sustainability<br />
//                     Adaptability
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </section>





//   );
// };

// export default OurCommitments;




// import React from "react";


//  import './OurCommitments.css'
// import MissionValuesVisionCombined from '../img/banner/values.jpg';

// import OurValuesHandPik from '../img/aaaservices/Vision.png';
// import ourVisionCardRed1 from '../img/aaaservices/Mission.png';
// import ourMissionCardRed2 from '../img/aaaservices/values.jpg';

// const OurCommitments = () => {
//   return (
//     <div style={{  }} className="ournot-data">
//       <div className="text-center">
//       <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
//                        Our <span style={{ color: "#3395AD" }}>Commitments</span>
//                        <span style={{
//                          position: 'absolute',
//                          bottom: '-8px',
//                          left: '50%',
//                          transform: 'translateX(-50%)',
//                          width: '50%',
//                          height: '2px',
//                          backgroundColor: '#000'
//                        }} />
//                      </h1>
//       </div>
//       <div className="wrapper">
//         <div className="row d-flex justify-content-center">
//           <div className="col-lg-3" style={{margin:'20px 40px'}}>
//             <div className="card">
//               <img src={OurValuesHandPik} alt="Our Vision" />
//               <div className="info" style={{
//                 display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
//                 justifyContent: 'center', height: '100%'
//               }}>
//                 <h1>Our Vision</h1>
//                 <p style={{ fontSize: '14px' }}> Our vision is to be the premier provider of integrated technology solutions, driving innovation and transformation for businesses worldwide. We aspire to create a future where seamless technology integration accelerates business success and unlocks limitless potential.</p>

//               </div>
//             </div>
//           </div>

//           <div className="col-lg-3" style={{margin:'20px 40px'}}>
//             <div className="card">
//               <img src={ourVisionCardRed1} alt="Our Mission" />
//               <div className="info" style={{
//                 display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
//                 justifyContent: 'center', height: '100%'
//               }}>
//                 <h1>Our Mission</h1>
//                 <p>our mission is to empower organizations by delivering customized, cutting-edge technology solutions that drive growth, enhance efficiency, and foster innovation. We are committed to understanding the unique needs of our clients and providing them with the tools and support they need to thrive in a rapidly evolving digital landscape.</p>

//               </div>
//             </div>
//           </div>

//           <div className="col-lg-3" style={{margin:'20px 40px'}}>
//             <div className="card">
//               <img src={ourMissionCardRed2} alt="Our Values" />
//               <div className="info" style={{
//                 display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
//                 justifyContent: 'center', height: '100%'
//               }}>
//                   <h1>Our Values</h1>
//                    <p>Innovation
//                      Integrity
//                      Collaboration
//                      Customer-Centricity
//                      Excellence
//                      Sustainability<br />
//                      Adaptability</p>

//               </div>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>


//   );
// };

// export default OurCommitments;


// import React from "react";

// const MissionVisionValues = () => {
//   const containerStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "30px",
//     backgroundColor: "#f9f9f9",
//     padding: "40px 20px",
//     flexWrap: "wrap",
//   };

//   const cardStyle = {
//     position: "relative",
//     width: "100%",
//     maxWidth: "300px",
//     padding: "30px 20px",
//     borderRadius: "20px",
//     backgroundColor: "#fff",
//     boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//     textAlign: "center",
//     overflow: "hidden",
//     transition: "transform 0.3s ease, box-shadow 0.3s ease",
//   };

//   const headerStyle = (color) => ({
//     backgroundColor: color,
//     height: "50px",
//     borderTopLeftRadius: "20px",
//     borderTopRightRadius: "20px",
//   });

//   const iconStyle = {
//     fontSize: "40px",
//     margin: "20px 0",
//     transition: "transform 0.3s ease",
//   };

//   const cardHoverStyle = {
//     transform: "translateY(-10px)",
//     boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
//   };

//   const iconHoverStyle = {
//     transform: "translateY(-10px)",
//   };

//   const textHoverStyle = {
//     fontWeight: "bold", // Make the text bold on hover
//     fontSize: "24px",   // Increase the font size
//     transition: "all 0.3s ease", // Smooth transition for font change
//   };

//   // Text content for each card
//   const cardContent = [
//     {
//       title: "Mission",
//       iconClass: "fa-bullseye",
//       description: "Our mission is to deliver excellence in everything we do, focused on making a positive impact."
//     },
//     {
//       title: "Vision",
//       iconClass: "fa-lightbulb",
//       description: "Our vision is to innovate and inspire, transforming the future with creativity and purpose."
//     },
//     {
//       title: "Values",
//       iconClass: "fa-balance-scale",
//       description: "Our values are rooted in integrity, collaboration, and accountability, guiding us in all decisions."
//     },
//   ];

//   return (
//     <div>
//       <div className="text-center">
//         <h1
//           style={{
//             color: "#000",
//             fontSize: "48px",
//             display: "inline-block",
//             position: "relative",
//             fontFamily: "lato,sans,serif",
//             backgroundColor: "#f9f9f9",
//           }}
//         >
//           Our <span style={{ color: "#3395AD" }}>Commitments</span>
//           <span
//             style={{
//               position: "absolute",
//               bottom: "-8px",
//               left: "50%",
//               transform: "translateX(-50%)",
//               width: "50%",
//               height: "2px",
//               backgroundColor: "#000",
//             }}
//           />
//         </h1>
//       </div>

//       <div style={containerStyle}>
//         {cardContent.map((card, index) => (
//           <div
//             key={card.title}
//             style={{
//               position: "relative",
//               ...cardStyle,
//             }}
//             onMouseEnter={(e) => {
//               e.currentTarget.style.transform = cardHoverStyle.transform;
//               e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow;
//               e.currentTarget.querySelector("i").style.transform = iconHoverStyle.transform;
//               e.currentTarget.querySelector("h3").style = textHoverStyle; // Apply text hover effect
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.transform = "none";
//               e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
//               e.currentTarget.querySelector("i").style.transform = "none";
//               e.currentTarget.querySelector("h3").style = {}; // Reset text style
//             }}
//           >
//             <div
//               style={headerStyle(
//                 index === 0 ? "black" : index === 1 ? "teal" : "black"
//               )}
//             ></div>
//             <i
//               style={iconStyle}
//               className={`fa ${card.iconClass}`}
//             ></i>
//             <h3>{card.title}</h3>
//             <p>{card.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default MissionVisionValues;




// import React from "react";
// import { motion } from "framer-motion";

// const Infographic = () => {
//   return (
//     <div style={styles.container}>
//       <div style={styles.banner}>OurCommitments</div>

//       <div style={styles.cardsContainer}>
//         <motion.div
//           style={{ ...styles.card, borderTop: "5px solid red" }}
//           initial={{ x: -100, opacity: 0 }}
//           animate={{ x: 0, opacity: 1 }}
//           transition={{ duration: 0.5 }}
//           whileHover={{ scale: 1.05 }}
//         >
//           <div style={{ ...styles.header, backgroundColor: "#d7a54b" }}>MISSION</div>
//           <div style={styles.icon}>🎯</div>
//           <p style={styles.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//         </motion.div>
//         <motion.div
//           style={{ ...styles.card, borderTop: "5px solid green" }}
//           initial={{ x: 100, opacity: 0 }}
//           animate={{ x: 0, opacity: 1 }}
//           transition={{ duration: 0.5 }}
//           whileHover={{ scale: 1.05 }}
//         >
//           <div style={{ ...styles.header, backgroundColor: "green" }}>VISION</div>
//           <div style={styles.icon}>👁️</div>
//           <p style={styles.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//         </motion.div>
//         <motion.div
//           style={{ ...styles.card, borderTop: "5px solid blue" }}
//           initial={{ x: -100, opacity: 0 }}
//           animate={{ x: 0, opacity: 1 }}
//           transition={{ duration: 0.5 }}
//           whileHover={{ scale: 1.05 }}
//         >
//           <div style={{ ...styles.header, backgroundColor: "blue" }}>VALUES</div>
//           <div style={styles.icon}>💎</div>
//           <p style={styles.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     textAlign: "center",
//     fontFamily: "Arial, sans-serif",
//     padding: "20px",

//     backgroundColor: "#f8f4ed",
//   },
//   banner: {
//     display: "inline-block",
//     backgroundColor: "#ff7f3f",
//     color: "white",
//     padding: "10px 20px",
//     borderRadius: "5px",
//     fontWeight: "bold",
//     fontSize: "20px",
//   },
//   subtitle: {
//     margin: "5px 0 20px 0",
//     fontSize: "14px",
//     color: "#666",
//   },
//   cardsContainer: {
//     display: "flex",
//     justifyContent: "center",
//     flexWrap: "wrap",
//     marginTop:'20px',
//     gap: "20px",

//   },
//   card: {
//     backgroundColor: "white",
//     borderRadius: "10px",
//     padding: "20px",
//     width: "280px",
//     textAlign: "center",
//     boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//     transition: "all 0.3s ease-in-out",
//   },
//   header: {
//     color: "white",
//     padding: "10px",
//     borderRadius: "5px 5px 0 0",
//     fontWeight: "bold",
//   },
//   icon: {
//     fontSize: "40px",
//     margin: "10px 0",
//   },
//   text: {
//     color: "#666",
//     fontSize: "14px",
//     transition: "color 0.3s ease-in-out",
//   },
// };

// export default Infographic;


// import React, { useEffect, useRef, useState } from "react";
// import { FaBullseye, FaEye, FaHandshake } from "react-icons/fa"; // Import icons

// const AnimatedBlocks = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const [expandedBlock, setExpandedBlock] = useState(null);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (containerRef.current) {
//         const rect = containerRef.current.getBoundingClientRect();
//         if (rect.top <= window.innerHeight * 0.75) {
//           setIsVisible(true);
//         }
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     handleScroll(); // Check on mount

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const toggleExpand = (block) => {
//     setExpandedBlock(expandedBlock === block ? null : block);
//   };

//   const data = [
//     { title: "Mission", text: "Our mission is to deliver innovative and reliable logistics solutions that drive efficiency and success for our clients worldwide.", icon: <FaBullseye /> },
//     { title: "Vision", text: "We envision a future where seamless and sustainable logistics solutions empower businesses to thrive in a rapidly evolving global market.", icon: <FaEye /> },
//     { title: "Value", text: "Integrity, excellence, and customer-centricity are the core values that guide our commitment to delivering superior logistics services.", icon: <FaHandshake /> }
//   ];

//   return (
//     <div style={styles.wrapper}>
//       <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato,sans,serif', fontSize: '48px', }}>
//           <div class="text-center"> <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif', textAlign: 'center' }}> Our <span style={{ color: "rgb(235, 195, 101)" }}>Commitments</span>
//           </h1></div>
//           <div
//             style={{
//               width: '200px', // Adjust the width of the line
//               height: '2px', // Thickness of the line
//               backgroundColor: '#000', // Black color
//               margin: '10px auto 0', // Center alignment and spacing
//               marginTop: '-10px'
//             }}
//           ></div>
//         </div>
// {/* Main heading */}
//       <div style={styles.container} ref={containerRef}>
//         {data.map((item, index) => (
//           <div
//             key={index}
//             style={{
//               ...styles.block,
//               ...(index % 2 === 0 ? styles.blockLeft : styles.blockRight),
//               ...(isVisible ? styles.animate : {}),
//             }}
//           >
//             <h3 style={styles.heading} onClick={() => toggleExpand(index)}>
//               {item.title} <span style={styles.icon}>{item.icon}</span>
//             </h3>
//             <p style={expandedBlock === index ? { ...styles.text, ...styles.visibleText } : styles.text}>
//               {item.text}
//             </p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const styles = {
//   wrapper: {
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   mainHeading: {
//     fontSize: "48px",
//     fontWeight: "bold",
//     marginBottom: "20px",
//     color: "#003366",
//   },

//     container: {
//       width: "88%",
//       margin: "50px auto",
//       display: "flex",
//       justifyContent: "center", // Center items horizontally
//       alignItems: "center",
//       flexWrap: "wrap",
//       gap: "20px",
//     },

//     // Responsive styles
//     "@media (max-width: 768px)": {
//       container: {
//         width: "95%", // Increase width for better fit
//         flexDirection: "column", // Stack items vertically
//         textAlign: "center", // Center text inside elements
//         alignItems: "center", // Ensure items are properly centered
//         justifyContent: "center",
//       },
//     },


//   block: {
//     position: "relative",
//     padding: "20px",
//     color: "#000",
//     width: "30%",
//     fontFamily: "Arial, sans-serif",
//     fontSize: "16px",
//     fontWeight: "400",
//     background: "#e7bd63",
//     overflow: "hidden",
//     opacity: 0,
//     transform: "translateY(50px)",
//     transition: "all 1.5s ease-in-out",
//     borderRadius: "12px",
//     boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
//     cursor: "pointer",
//     minWidth: "250px",
//   },
//   blockRight: {
//     transform: "translateX(100%)",
//   },
//   blockLeft: {
//     transform: "translateX(-100%)",
//   },
//   animate: {
//     opacity: 1,
//     transform: "translateX(0)",
//   },
//   heading: {
//     textAlign: "left",
//     margin: "0",
//     padding: "10px 0",
//     fontFamily: "roboto",
//     fontSize: "22px",
//     cursor: "pointer",
//     color: "#003366",
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   icon: {
//     fontSize: "22px",
//     color: "#003366",
//     marginLeft: "10px",
//   },
//   text: {
//     textAlign: "left",
//     padding: "0 10px",
//     marginTop: "10px",
//     fontSize: "16px",
//     transition: "max-height 0.5s ease-in-out, opacity 0.5s",
//     maxHeight: "0",
//     opacity: "0",
//     overflow: "hidden",
//   },
//   visibleText: {
//     maxHeight: "200px",
//     opacity: "1",
//   },
// };

// export default AnimatedBlocks;

// import { motion } from "framer-motion";
// import Image from "../img/aaaservices/mission.png"; // Ensure correct image path

// const AnimatedComponent = () => {
//   return (
//     <div>
//     <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato,sans,serif', fontSize: '48px', }}>
//                <div class="text-center"> <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif', textAlign: 'center' }}> Our <span style={{ color: "rgb(235, 195, 101)" }}>Commitments</span>
//                </h1></div>
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         height: "100vh",
//         padding: "24px",

//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           width: "100%",
//           maxWidth: "80rem",
//           overflow: "hidden",
//           borderRadius: "16px",
//           boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",

//         }}
//       >
//         {/* Image Section */}
//         <motion.div
//           style={{
//             width: "50%",
//             height: "24rem",
//             backgroundImage: `url(${Image})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//           }}
//           initial={{ x: "-100%", opacity: 0 }}
//           animate={{ x: 0, opacity: 1 }}
//           transition={{ type: "spring", stiffness: 100 }}
//         ></motion.div>

//         {/* Content Section */}
//         <motion.div
//           style={{
//             width: "50%",
//             padding: "32px",
//           }}
//           initial={{ x: "100%", opacity: 0 }}
//           animate={{ x: 0, opacity: 1 }}
//           transition={{ type: "spring", stiffness: 100 }}
//         >
//           <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "#1f2937",textAlign:'left' }}>
//            Mission
//           </h2>
//           <p style={{ marginTop: "16px", color: "#4b5563",textAlign:'left' }}>
//           We envision a future where seamless and sustainable logistics solutions empower businesses to thrive in a rapidly evolving global market
//           </p>

//           <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "#1f2937",textAlign:'left' }}>
//           Vision
//           </h2>
//           <p style={{ marginTop: "16px", color: "#4b5563",textAlign:'left' }}>
//           Our mission is to deliver innovative and reliable logistics solutions that drive efficiency and success for our clients worldwide
//           </p>

//           <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "#1f2937",textAlign:'left' }}>
//         values
//           </h2>
//           <p style={{ marginTop: "16px", color: "#4b5563",textAlign:'left' }}>
//           Integrity, excellence, and customer-centricity are the core values that guide our commitment to delivering superior logistics services
//           </p>
//         </motion.div>
//       </div>
//     </div>
//     </div>
//     </div>
//   );
// };

// export default AnimatedComponent;



// import { motion } from "framer-motion";
// import Image from "../img/aaaservices/misiom2.png"; // Ensure correct image path

// const AnimatedComponent = () => {
//   return (

//     <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato,sans,serif', fontSize: '48px', }}>
//                <div class="text-center"> <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif', textAlign: 'center' }}> Our <span style={{ color: "rgb(235, 195, 101)" }}>Commitments</span>
//                 </h1></div>
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         height: "110vh",
//         padding: "24px",
//         flexWrap: "wrap",

//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           flexWrap: "wrap",
//           width: "100%",
//           maxWidth: "80rem",
//           overflow: "hidden",
//         }}
//       >
//         {/* Image Section */}
//         <motion.div
//           style={{
//             width: "42%",
//             height: "31rem",
//             backgroundImage: `url(${Image})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//             borderRadius: "12px",
//             margin: "auto",
//           }}
//           initial={{ x: "-100%", opacity: 0 }}
//           animate={{ x: 0, opacity: 1 }}
//           transition={{ type: "spring", stiffness: 100 }}
//         ></motion.div>

//         {/* Content Section */}
//         <div style={{ width: "50%", padding: "32px" }}>
//           <motion.div
//             style={{
//               backgroundColor: "rgb(235, 195, 101)",
//               borderRadius: "12px",
//               color: "black",
//               padding: "20px",
//               marginBottom: "20px",
//               textAlign: "left",
//             }}
//             initial={{ x: "100%", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ type: "spring", stiffness: 100 }}
//           >
//             <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "red" }}>Mission</h2>
//             <p>We envision a future where seamless and sustainable logistics solutions empower businesses to thrive in a rapidly evolving global market.</p>
//           </motion.div>

//           <motion.div
//             style={{
//               backgroundColor: "rgb(235, 195, 101)",
//               borderRadius: "12px",
//               color: "black",
//               padding: "20px",
//               marginBottom: "20px",
//               textAlign: "left",
//             }}
//             initial={{ x: "100%", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ type: "spring", stiffness: 100 }}
//           >
//             <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "red" }}>Vision</h2>
//             <p>Our mission is to deliver innovative and reliable logistics solutions that drive efficiency and success for our clients worldwide.</p>
//           </motion.div>

//           <motion.div
//             style={{
//               backgroundColor: "yellow",
//               borderRadius: "12px",
//               color: "black",
//               padding: "20px",
//               textAlign: "left",
//             }}
//             initial={{ x: "100%", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ type: "spring", stiffness: 100 }}
//           >
//             <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "red" }}>Values</h2>
//             <p>Integrity, excellence, and customer-centricity are the core values that guide our commitment to delivering superior logistics services.</p>
//           </motion.div>
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default AnimatedComponent;



import { motion } from "framer-motion";
import Image from "../img/banner/0.png"; // Ensure correct image path

const AnimatedComponent = () => {
  return (
    <div style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'lato, sans-serif', fontSize: '48px' }}>
      <div className="text-center" style={{ marginTop: '-29px' }}>
        <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'roboto', textAlign: 'center' }}>
          Our <span style={{ color: "rgb(235, 195, 101)" }}>Commitments</span>
        </h1>
        <div style={{
          width: "200px",
          height: "2px",
          backgroundColor: "rgb(0, 0, 0)",
          margin: "0px auto",
          marginTop: "-27px"
        }}></div>

      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          padding: "24px",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            maxWidth: "80rem",
            overflow: "hidden",
            flexDirection: "row",
          }}
        >
          {/* Image Section */}
          <motion.div
            style={{
              width: "48%",
              height: "28rem",
              backgroundImage: `url(${Image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "12px",
              margin: "auto",
            }}
            initial={{ x: "-100%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 100 }}
            className="our-img"
          ></motion.div>

          {/* Content Section */}
          <div style={{ width: "50%", padding: "32px" }}>
            <motion.div
              style={{
                backgroundColor: "rgb(235, 195, 101)",
                borderRadius: "0px 50px 0px",
                color: "black",
                padding: "20px",
                marginBottom: "20px",
                textAlign: "left",
              }}
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 100 }}
            >
              <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "black" }}>Mission</h2>
              <p style={{textAlign:'left'}}>Our mission is to deliver exceptional travel services with a strong focus on safety, reliability, and customer satisfaction. We strive to enhance the commuting experience through well-maintained vehicles, trained drivers, and a commitment to punctuality and professionalism..</p>
            </motion.div>

            <motion.div
              style={{
               
                backgroundColor: "rgb(49, 56, 64)",
                borderRadius: "0px 50px 0px",
                color: "white",
                padding: "20px",
                marginBottom: "20px",
                textAlign: "left",
              }}
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 100 }}
            >
              <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "white" }}>Vision</h2>
              <p  style={{textAlign:'left'}}>Our vision is to be the most trusted and customer-centric travel partner, offering seamless and safe transportation solutions. We aim to provide a comfortable and hassle-free travel experience, ensuring long-term relationships with our valued clients.</p>
            </motion.div>

            <motion.div
              style={{
                backgroundColor: "rgb(235, 195, 101)",
                borderRadius: "0px 50px 0px",
                color: "black",
                padding: "20px",
                textAlign: "left",
              }}
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 100 }}
            >
              <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "black" }}>Values</h2>
              <p  style={{textAlign:'left'}}>We operate with integrity and reliability, building trust through transparency and punctual service. We believe in fostering a positive and supportive work environment for our employees, knowing that happy staff leads to happy customers. Additionally, we embrace continuous improvement, </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Responsive CSS */}
      <style>
        {`
          @media (max-width: 1024px) {
           

            div[style*="width: 50%"] {
              width: 100% !important;
              padding: 16px !important;
            }
          }

          @media (max-width: 768px) {
            h1 {
              font-size: 36px !important;
            }

            h2 {
              font-size: 20px !important;
            }

          

            p {
              font-size: 14px !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AnimatedComponent;

















