// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import aboutUsImage from "../img/aaaservices/black.png";

// const AboutUs = () => {
//   const [showMore, setShowMore] = useState(false);

//   return (
//     <section className="application" style={{  }}>
//       <div className="con" style={{padding:'none',backgroundColor:'white'}}>
//         <div 
//           className="row align-items-center"
//           style={{ display: "flex", flexWrap: "wrap", marginTop: "-12px" }}
//         >
//           {/* Right Side: About Image */}
//           <motion.div
//             className="col-lg-6 col-md-12 order-lg-1 text-center"
//             initial={{ x: "-100vw", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ duration: 1, ease: "easeOut" }}
//           >
//             <div>
//               <img
//                 src={aboutUsImage}
//                 alt="About Us"
//                 className="img-fluid"
//                 style={{
//                   maxWidth: "100%",
//                   height: "auto",
//                   borderRadius: "10px",
//                   display: "block",
//                   marginRight: "auto" // Align image to left side
//                 }}
//               />
//             </div>
//           </motion.div>

//           {/* Left Side: About Content (Text) */}
//           <motion.div
//             className="col-lg-6 col-md-12 order-lg-2"
//             initial={{ x: "100vw", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ duration: 1, ease: "easeOut" }}
//             style={{ textAlign: "left", maxWidth: "600px" }}
//           >
           
//             <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
//               Welcome to <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>Sri Shiva Travels</span>, started its journey on 23rd Dec 2022 with the mission to offer timely and professional transfer service...
//             </p>
//             <h5 style={{ fontSize: "20px", fontFamily: "Lato, sans-serif", position: "relative", display: "inline-block" }}>
//               Professional <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
//               <span style={{ position: "absolute", bottom: "-8px", left: "0", width: "50%", height: "2px", backgroundColor: "#000" }} />
//             </h5>
//             <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
//               Since we are based in IT hub city, we have excellent staff, technology and safe cars...
//             </p>
            
//             {showMore && (
//               <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
//                 We have grown to over 1000+ units, including our own and attached fleet. Our service ensures...
//               </p>
//             )}
            
//             <button
//               onClick={() => setShowMore(!showMore)}
//               style={{
//                 marginTop: "10px",
//                 backgroundColor: "rgb(235, 195, 101)",
//                 color: "#000",
//                 border: "none",
//                 padding: "10px 20px",
//                 fontSize: "16px",
//                 cursor: "pointer",
//                 borderRadius: "5px",
//               }}
//             >
//               {showMore ? "View Less" : "View More"}
//             </button>
//           </motion.div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AboutUs;



// import React from "react";
// import { motion } from "framer-motion";
// import aboutUsImage from "../img/aaaservices/employe.jpg"; // Import the image

// const AboutUs = () => {
//   return (
//     <section 
//       className="about-area ptb-100"
//       style={{ 
//         // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
//         padding: "0px 0",
       
//       }}
//     >
//       <div className="container">
//         <div 
//           className="row align-items-center"
//           style={{ display: "flex", flexWrap: "wrap",marginTop:'-12px' }}
//         >
//  <motion.div
//             className="col-lg-6 col-md-12 text-center"
//             initial={{ x: "100vw", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ duration: 1, ease: "easeOut" }}
//           >
//             <div>
//               <img
//                 src={aboutUsImage}
//                 alt="About Us"
//                 className="img-fluid"
//                 style={{
//                   maxWidth: "100%", 
//                   height: "auto",
//                   borderRadius: "10px",
//                   display: "block",
//                   marginLeft: "auto"  // Align image to right side
//                 }}
//               />
//             </div>
//           </motion.div >

//           {/* Left Side: About Content (Text) */}
//           <motion.div
//             className="col-lg-6 col-md-12"
//             initial={{ x: "-100vw", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ duration: 1, ease: "easeOut" }}
//             style={{
//               textAlign: "left",
//               maxWidth: "600px", // Control text width
//             }}
//           >
//             <h1 
//               style={{ 
//                 fontSize: "30px", 
//                 fontFamily: "Lato, sans-serif", 
//                 position: "relative", 
//                 display: "inline-block"
//               }}
//             >
//              Employee <span style={{ color: "rgb(235, 195, 101)" }}>Transportation</span>
//               <span
//                 style={{
//                   position: "absolute",
//                   bottom: "-8px",
//                   left: "0",
//                   width: "50%",
//                   height: "2px",
//                   backgroundColor: "#000",
//                 }}
//               />
//             </h1>

//             <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
             
//               <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>
//                 Sri Shiva Travels
//               </span>,  provides employees an alternative to driving and parking every day.Our professional chauffeurs arrive on time and get you safely to your destination. No stress, no hassle, no problem. Sri Shiva Travels is associated with major MNC’s, BPO’s & KPO’s by providing vehicles ranging from 4 Seaters to 50 Seaters for their Employee Transportation. We provide pickup and drop for more than 5,000 employees every day
//             </p>
//             <h5 
//               style={{ 
//                 fontSize: "20px", 
//                 fontFamily: "Lato, sans-serif", 
//                 position: "relative", 
//                 display: "inline-block"
//               }}
//             >
//              Professional  <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
//               <span
//                 style={{
//                   position: "absolute",
//                   bottom: "-8px",
//                   left: "0",
//                   width: "50%",
//                   height: "2px",
//                   backgroundColor: "#000",
//                 }}
//               />
//             </h5>
//             <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
//             Since we are based in IT hub city, we have excellent staff, technology and safe cars are what makes us the best choice of our customers and it also put us a head of the competition. Due to our dedication and professional service, today we have grown to over 1000+ units including our own and attached fleet.
//             </p>
//           </motion.div>

//           {/* Right Side: About Image */}
         
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AboutUs;



// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import aboutUsImage from "../img/aaaservices/employe.jpg"; // Import the image

// const AboutUs = () => {
//   const [showMore, setShowMore] = useState(false);

//   return (
//     <section 
//       className="about-area ptb-100"
//       style={{ padding: "0px 0" }}
//     >
//       <div className="container">
//         <div 
//           className="row align-items-center"
//           style={{ display: "flex", flexWrap: "wrap", marginTop: "-12px" }}
//         >
//           {/* Right Side: About Image */}
//           <motion.div
//             className="col-lg-6 col-md-12 text-center"
//             initial={{ x: "100vw", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ duration: 1, ease: "easeOut" }}
//           >
//             <div>
//               <img
//                 src={aboutUsImage}
//                 alt="About Us"
//                 className="img-fluid"
//                 style={{
//                   maxWidth: "100%", 
//                   height: "auto",
//                   borderRadius: "10px",
//                   display: "block",
//                   marginLeft: "auto"  // Align image to right side
//                 }}
//               />
//             </div>
//           </motion.div>

//           {/* Left Side: About Content (Text) */}
//           <motion.div
//             className="col-lg-6 col-md-12"
//             initial={{ x: "-100vw", opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ duration: 1, ease: "easeOut" }}
//             style={{
//               textAlign: "left",
//               maxWidth: "600px", // Control text width
//             }}
//           >
//             <h1 
//               style={{ 
//                 fontSize: "30px", 
//                 fontFamily: "Lato, sans-serif", 
//                 position: "relative", 
//                 display: "inline-block"
//               }}
//             >
//              Employee <span style={{ color: "rgb(235, 195, 101)" }}>Transportation</span>
//               <span
//                 style={{
//                   position: "absolute",
//                   bottom: "-8px",
//                   left: "0",
//                   width: "50%",
//                   height: "2px",
//                   backgroundColor: "#000",
//                 }}
//               />
//             </h1>

//             <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
//               <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>
//                 Sri Shiva Travels
//               </span>, provides employees an alternative to driving and parking every day. Our professional chauffeurs arrive on time and get you safely to your destination. No stress, no hassle, no problem. Sri Shiva Travels is associated with major MNC’s, BPO’s & KPO’s by providing vehicles ranging from 4 Seaters to 50 Seaters for their Employee Transportation. We provide pickup and drop for more than 5,000 employees every day.
//             </p>

           
//             {/* View More Section */}
//             {showMore && (
//               <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
//                  <h5 
//               style={{ 
//                 fontSize: "20px", 
//                 fontFamily: "Lato, sans-serif", 
//                 position: "relative", 
//                 display: "inline-block"
//               }}
//             >
//              Professional <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
//               <span
//                 style={{
//                   position: "absolute",
//                   bottom: "-8px",
//                   left: "0",
//                   width: "50%",
//                   height: "2px",
//                   backgroundColor: "#000",
//                 }}
//               />
//             </h5>

//             <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
//               Since we are based in an IT hub city, we have excellent staff, technology, and safe cars that make us the best choice for our customers and put us ahead of the competition. Due to our dedication and professional service, today we have grown to over 1000+ units, including our own and attached fleet.
//             </p>

//                 Our fleet management ensures the highest level of safety, comfort, and convenience for employees traveling daily. We are committed to providing reliable and timely transportation, ensuring employees reach their destinations stress-free. Our advanced tracking and scheduling systems optimize routes for maximum efficiency, making Sri Shiva Travels a trusted partner for corporate transportation needs.
//               </p>
//             )}

//             {/* View More Button */}
//             <button
//               onClick={() => setShowMore(!showMore)}
//               style={{
//                 marginTop: "10px",
//                 backgroundColor: "#ebc365",
//                 border: "none",
//                 padding: "10px 20px",
//                 cursor: "pointer",
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 color: "#000",
//                 borderRadius: "5px",
//                 transition: "background 0.3s ease-in-out"
//               }}
//             >
//               {showMore ? "View Less" : "View More"}
//             </button>
//           </motion.div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AboutUs;




import React, { useState } from "react";
import { motion } from "framer-motion";
import aboutUsImage from "../img/aaaservices/employe.jpg"; // Import the image

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <section className="about-area ptb-100" style={{ padding: "0px 0" }}>
      <div className="container" >
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "flex-start", // Image fixed rahega, text badhega
            flexWrap: "wrap",
            marginTop: "-12px",
          }}
        >
          {/* Right Side: About Image (Fixed Position) */}
          <motion.div
            className="col-lg-6 col-md-12 text-center"
            initial={{ x: "100vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
            style={{
              position: "sticky", // Yeh Image ko fix karega
              top: "10px", // Yeh Image ko scroll ke time fix rakhega
            }}
          >
            <div>
              <img
                src={aboutUsImage}
                alt="About Us"
                className="img-fluid"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "10px",
                  display: "block",
                  marginLeft: "auto",
                }}
              />
            </div>
          </motion.div>

          {/* Left Side: About Content (Expanding Text) */}
          <motion.div
            className="col-lg-6 col-md-12"
            initial={{ x: "-100vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
            
            style={{
              textAlign: "left",
              maxWidth: "600px",
               paddingLeft: "20px",
              transition: "max-height 0.5s ease-in-out", // Smooth expansion
              overflow: "hidden", // Extra content ko smoothly appear hone dega
            }}
          >
           <h1 
              style={{ 
                fontSize: "30px", 
                fontFamily: "Lato, sans-serif", 
                position: "relative", 
                display: "inline-block"
              }}
            >
             Employee <span style={{ color: "rgb(235, 195, 101)" }}>Transportation</span>
              <span
                style={{
                  position: "absolute",
                  bottom: "-8px",
                  left: "0",
                  width: "50%",
                  height: "2px",
                  backgroundColor: "#000",
                }}
              />
            </h1>

            <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
              <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>Sri Shiva Travels</span>,
              provides employees an alternative to driving and parking every day. Our professional chauffeurs arrive on time and get you safely to your destination. No stress, no hassle, no problem. Sri Shiva Travels is associated with major MNC’s, BPO’s & KPO’s by providing vehicles ranging from 4 Seaters to 50 Seaters for their Employee Transportation. We provide pickup and drop for more than 5,000 employees every day
            </p>

            {/* View More Section */}
            <div style={{ maxHeight: showMore ? "1000px" : "0px", overflow: "hidden", transition: "max-height 0.5s ease-in-out" }}>
            <h5 
              style={{ 
                fontSize: "20px", 
                fontFamily: "Lato, sans-serif", 
                position: "relative", 
                display: "inline-block"
              }}
            >
             Professional <span style={{ color: "rgb(235, 195, 101)" }}>Service</span>
              <span
                style={{
                  position: "absolute",
                  bottom: "-8px",
                  left: "0",
                  width: "50%",
                  height: "2px",
                  backgroundColor: "#000",
                }}
              />
            </h5>
              <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
              Since we are based in an IT hub city, we have excellent staff, technology, and safe cars that make us the best choice for our customers and put us ahead of the competition. Due to our dedication and professional service, today we have grown to over 1000+ units, including our own and attached fleet.
              </p>
              <p style={{ fontSize: "16px", textAlign: "justify", lineHeight: "1.8" }}>
              Our fleet management ensures the highest level of safety, comfort, and convenience for employees traveling daily. We are committed to providing reliable and timely transportation, ensuring employees reach their destinations stress-free. Our advanced tracking and scheduling systems optimize routes for maximum efficiency, making Sri Shiva Travels a trusted partner for corporate transportation needs.
              </p>
            </div>

            {/* View More Button */}
            <button
              onClick={() => setShowMore(!showMore)}
              style={{
                marginTop: "10px",
                backgroundColor: "#ebc365",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#000",
                borderRadius: "5px",
                transition: "background 0.3s ease-in-out",
              }}
            >
              {showMore ? "View Less" : "View More"}
            </button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;


