import React, { useState } from "react";
import miniBus from "../img/aaaservices/minibus.png";
import luxuryBus from "../img/banner/LUEXERYBUS.jpg";
import luxuryBus1 from "../img/aaaservices/p.png";
import luxuryBus2 from "../img/aaaservices/slepear bus.png";
import luxuryBus3 from "../img/aaaservices/midibus.png";

const buses = [
  {
    name: "Mini Bus",
    image: miniBus,
    features: ["AC", "20 Seats", "GPS", "Music", "Luggage Space"],
  },
  {
    name: "Luxury Bus",
    image: luxuryBus,
    features: ["AC", "20 Seats", "GPS", "Music", "Luggage Space"],
  },
  {
    name: "Standard Bus",
    image: luxuryBus1,
    features: ["AC", "20 Seats", "GPS", "Music", "Luggage Space"],
  },
  {
    name: "Sleeper Bus",
    image: luxuryBus2,
    features: ["AC", "20 Seats", "GPS", "Music", "Luggage Space"],
  },
  {
    name: "Midi Bus",
    image: luxuryBus3,
    features: ["AC", "20 Seats", "GPS", "Music", "Luggage Space"],
  },
];

const BusFilter = () => {
  const [selectedBus, setSelectedBus] = useState(buses[0]);

  return (
    <div style={{ textAlign: "center", padding: "20px", fontFamily: "Arial, sans-serif" }}>
      {/* Bus Section */}
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", gap: "132px",  }}>
        
        {/* Bus Filter Buttons */}
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {buses.map((bus) => (
            <button
              key={bus.name}
              style={{
                padding: "10px 15px",
                border: "none",
                backgroundColor: selectedBus.name === bus.name ? "#ffcc00" : "#f0f0f0",
                cursor: "pointer",
                borderRadius: "18px",
                fontWeight: "bold",
              }}
              onClick={() => setSelectedBus(bus)}
            >
              {bus.name}
            </button>
          ))}
        </div>

        {/* Bus Image and Features */}
        <div style={{ textAlign: "center" }}>
          <img src={selectedBus.image} alt={selectedBus.name} style={{ width: "324px", height: "auto", borderRadius: "30px" }} />
          <div style={{ marginTop: "20px", display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center" }}>
            {selectedBus.features.map((feature, index) => (
              <span key={index} style={{ padding: "8px 12px", backgroundColor: "#007bff", color: "#fff", borderRadius: "4px", fontSize: "14px" }}>
                {feature}
              </span>
            ))}
          </div>
        </div>

        {/* Enquiry Form */}
        <div style={{ width: "300px", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", position: "relative" }}>
          <h3 style={{ textAlign: "center",color:'#ffca00' }}>Enquiry Form</h3>
          <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <input type="text" placeholder="Your Name" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <input type="email" placeholder="Your Email" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <input type="tel" placeholder="Your Phone" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
            <textarea placeholder="Your Message" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", resize: "none" }}></textarea>
            <button type="submit" style={{ padding: "10px", backgroundColor: "#ffcc00", border: "none", borderRadius: "4px", cursor: "pointer", fontWeight: "bold" }}>Submit</button>
          </form>
        </div>

      </div>
    </div>
  );
};

export default BusFilter;




// import React, { useState } from "react";
// import { FaBars } from "react-icons/fa";
// import miniCar from "../img/banner/MINI CAR.jpg";
// import sedanCar from "../img/banner/SEDAN IMAGE.png";
// import premiumSedanCar from "../img/banner/PREMIEUM SEDAN.jpg";
// import suvCar from "../img/banner/PREMIEUM SEDAN.jpg";
// import premiumSuvCar from "../img/banner/PREMIEUM SEDAN.jpg";
// import miniBus from "../Component/MINI BUS.avif";
// import luxuryBus from "../img/banner/LUEXERYBUS.jpg";

// const cars = [
//   { name: "MINI", image: miniCar, features: ["AC", "Mobile Charger", "Sanitizer", "4 Seats", "GPS", "FirstAid Kit", "2 Bags"] },
//   { name: "SEDAN", image: sedanCar, features: ["AC", "Mobile Charger", "5 Seats", "GPS", "Music", "3 Bags"] },
//   { name: "PREMIUM SEDAN", image: premiumSedanCar, features: ["AC", "Mobile Charger", "Luxury Seats", "GPS", "FirstAid Kit", "Music", "4 Bags"] },
//   { name: "SUV", image: suvCar, features: ["AC", "Mobile Charger", "7 Seats", "GPS", "FirstAid Kit", "Music", "5 Bags"] },
//   { name: "PREMIUM SUV", image: premiumSuvCar, features: ["AC", "Mobile Charger", "Luxury Seats", "GPS", "FirstAid Kit", "Music", "6 Bags"] },
// ];

// const buses = [
//   { name: "Mini Bus", image: miniBus, features: ["AC", "20 Seats", "GPS", "Music", "Luggage Space"] },
//   { name: "Luxury Bus", image: luxuryBus, features: ["AC", "30 Seats", "Recliner Seats", "GPS", "Music", "TV"] },
// ];

// const CarFilter = () => {
//   const [activeCategory, setActiveCategory] = useState("cars");
//   const [selectedItem, setSelectedItem] = useState(cars[0]);
//   const [menuOpen, setMenuOpen] = useState(false);

//   return (
//     <div style={{ textAlign: "center", padding: "20px", fontFamily: "Arial, sans-serif" }}>
//       {/* Menu Icon */}
//       <div style={{ position: "relative", display: "inline-block", marginBottom: "20px" }}>
//         <div
//           style={{
//             width: "50px",
//             height: "50px",
//             borderRadius: "50%",
//             backgroundColor: "#ffcc00",
//             display: "flex",
//             alignItems: "center",
//             textAlign:'left',
//             justifyContent: "center",
//             marginLeft:"-522px",
//             cursor: "pointer",
//           }}
//           onClick={() => setMenuOpen(!menuOpen)}
//         >
//           <FaBars size={24} />
//         </div>
//         {menuOpen && (
//           <div
//             style={{
//               position: "absolute",
//               top: "60px",
//               left: "-500px",
//               transform: "translateX(-50%)",
//               backgroundColor: "white",
//               boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
//               borderRadius: "8px",
//               padding: "10px",
//               zIndex: 10,
//             }}
//           >
//             <button
//               style={{ display: "block", padding: "10px", cursor: "pointer", fontWeight: "bold", border: "none", width: "100%" }}
//               onClick={() => { setActiveCategory("cars"); setSelectedItem(cars[0]); setMenuOpen(false); }}
//             >
//               Cars
//             </button>
//             <button
//               style={{ display: "block", padding: "10px", cursor: "pointer", fontWeight: "bold", border: "none", width: "100%" }}
//               onClick={() => { setActiveCategory("buses"); setSelectedItem(buses[0]); setMenuOpen(false); }}
//             >
//               Buses
//             </button>
//           </div>
//         )}
//       </div>

//       {/* Content Section */}
//       <div style={{ display: "flex", justifyContent: "center", gap: "113px" }}>
//         <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
//           {(activeCategory === "cars" ? cars : buses).map((item) => (
//             <button
//               key={item.name}
//               style={{
//                 padding: "10px 15px",
//                 border: "none",
//                 backgroundColor: selectedItem.name === item.name ? "#ffcc00" : "#f0f0f0",
//                 cursor: "pointer",
//                 fontWeight: "bold",
//               }}
//               onClick={() => setSelectedItem(item)}
//             >
//               {item.name}
//             </button>
//           ))}
//         </div>
//         <div style={{ textAlign: "center" }}>
//           <img src={selectedItem.image} alt={selectedItem.name} style={{ width: "431px", height: "auto", borderRadius: "30px" }} />
//           <div style={{ marginTop: "20px", display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center" }}>
//             {selectedItem.features.map((feature, index) => (
//               <span key={index} style={{ padding: "8px 12px", backgroundColor: "#007bff", color: "#fff", borderRadius: "4px", fontSize: "14px" }}>{feature}</span>
//             ))}
//           </div>
//         </div>
//          {/* Enquiry Form */}
//         <div style={{ width: "300px", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", position: "relative" }}>
//           <h3 style={{ textAlign: "center" }}>Enquiry Form</h3>
//         <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>          <input type="text" placeholder="Your Name" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
//             <input type="email" placeholder="Your Email" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
//             <input type="tel" placeholder="Your Phone" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }} />
//             <textarea placeholder="Your Message" required style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", resize: "none" }}></textarea>
//             <button type="submit" style={{ padding: "10px", backgroundColor: "#ffcc00", border: "none", borderRadius: "4px", cursor: "pointer", fontWeight: "bold" }}>Submit</button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CarFilter;



