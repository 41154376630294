


import React from 'react';
import collageImage from '../img/aaaservices/why.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers ,
  faPalette,
  faHandshake,
  faShieldHalved,
  faShieldAlt,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us-area">
      <div className="container-fluid">
        <div className="row">
          {/* Image Section */}
          <div className="col-lg-5 col-md-12">
            <div className="why-choose-us-image">
              <img
                src={collageImage}
                alt="About Home"
                style={{ maxHeight: "795px", width: '100%', height: '747px' }}
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-7 col-md-12">
            <div className="why-choose-us-content">
              <div className="contents">
                <h2 style={{ color: '#fff', marginBottom: '45px', textAlign: 'center', fontWeight: '400;' }}>
                  Why <span style={{ color: 'rgb(235, 195, 101)' }}>Choose Us</span>
                </h2>
                <p style={{fontFamily:'Lato, sans-serif',textAlign:'left',fontSize: '12px'}}>
  <span style={{ fontFamily: 'Lato, sans-serif', fontSize: '18px', }}>
    Transforming Your Vision into Reality with Cutting-Edge Technology. Discover Why&nbsp;
  </span>

  <span style={{ fontFamily: 'Raqupine Regular', color: 'rgb(235, 195, 101)', fontSize: '18px' }}>
    Sri Shiva Travels
  </span >  
  is the Right Choice for Your Journey.
</p>



                {/* Features List */}
                <ul className="features-list">
                  <li>
                    <div className="icon">
                    <FontAwesomeIcon icon={faUsers} />
                    </div>
                    <span style={{fontFamily:'roboto',color:'rgb(235, 195, 101)'}}>Professional Teams
                    </span>
                    Our team of seasoned professionals harnesses state-of-the-art virtual reality  
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faHandshake} />
                    </div>
                    <span style={{fontFamily:'roboto',color:'rgb(235, 195, 101)'}}>Several Blue Chip clients</span>
                    We proudly serve a diverse portfolio of blue-chip clients, including industry leaders and 
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faHandshake} />
                    </div>
                    <span style={{fontFamily:'roboto',color:'rgb(235, 195, 101)'}}>Compliance / Background checks</span>
                    These checks verify identity, criminal records, employment history, financial
                  </li>

                  <li>
                    <div className="icon">
                    <FontAwesomeIcon icon={faShieldHalved} />
                    </div>
                    <span style={{fontFamily:'roboto',color:'rgb(235, 195, 101)'}}>Focus on Safety</span>
                    Focus on Safety refers to prioritizing the well-being of individuals, assets, and the environment by implementing 
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faShieldAlt} />
                    </div>
                    <span style={{fontFamily:'roboto',color:'rgb(235, 195, 101)'}}>Verification of Chauffeurs</span>
                    Ensuring passenger safety through thorough background checks, license verification, 
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faUserCheck} />
                    </div>
                    <span style={{fontFamily:'roboto',color:'rgb(235, 195, 101)'}}>24/7 operations support</span>
                    Round-the-clock assistance to ensure seamless travel experiences, 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;



