


import React from 'react';
import CountUp from 'react-countup';

// COMMERCIAL INTERIORS Icons
import commercialClientIcon from '../img/aaaservices/caricon2.png';
import commercialExperienceIcon from '../img/aaaservices/caricon2.png';
import commercialVehiclesIcon from '../img/aaaservices/caricon2.png';

// HOSPITALITY INTERIORS Icons
import hospitalityClientIcon from '../img/aaaservices/busicon2.png';
import hospitalityExperienceIcon from '../img/aaaservices/busicon2.png';
import hospitalityVehiclesIcon from '../img/aaaservices/busicon2.png';

// SHOWCASE INTERIORS Icons
import showcaseClientIcon from '../img/aaaservices/lux2.png';
import showcaseExperienceIcon from '../img/aaaservices/lux2.png';
import showcaseVehiclesIcon from '../img/aaaservices/lux2.png';

// OUTDOOR INTERIORS Icons
import outdoorClientIcon from '../img/aaaservices/bus1s.png';
import outdoorExperienceIcon from '../img/aaaservices/bus1s.png';
import outdoorVehiclesIcon from '../img/aaaservices/bus1s.png';
const FullStack = ({ selectedFilter }) => {
  // Default category if selectedFilter is null
  const defaultFilter = "COMMERCIAL_INTERIORS";
  const currentFilter = selectedFilter || defaultFilter;

  // Data for each category
  const data = {
    COMMERCIAL_INTERIORS: {
      clients: 20,
      experience: 10,
      vehicles: 15,
    },
    HOSPITALITY_INTERIORS: {
      clients: 30,
      experience: 15,
      vehicles: 50,
    },
    SHOWCASE_INTERIORS: {
      clients: 20,
      experience: 8,
      vehicles: 25,
    },
    OUTDOOR_INTERIORS: {
      clients: 15,
      experience: 5,
      vehicles: 10,
    },
  };

  // Labels for each category
  const labels = {
    COMMERCIAL_INTERIORS: {
      clients: "7-seater Car",
      experience: "5-seater Car",
      vehicles: "6-seater Car",
    },
    HOSPITALITY_INTERIORS: {
      clients: "40-seater Bus",
      experience: "60-seater Bus",
      vehicles: "48-seater Bus",
    },
    SHOWCASE_INTERIORS: {
      clients: "VIP Cars",
      experience: "Luxury Cars",
      vehicles: "Premium Cars",
    },
    OUTDOOR_INTERIORS: {
      clients: "15-seater Bus",
      experience: "20-seater Bus",
      vehicles: "26-seater Bus",
    },
  };

  // Icons for each category
  const icons = {
    COMMERCIAL_INTERIORS: [commercialClientIcon, commercialExperienceIcon, commercialVehiclesIcon],
    HOSPITALITY_INTERIORS: [hospitalityClientIcon, hospitalityExperienceIcon, hospitalityVehiclesIcon],
    SHOWCASE_INTERIORS: [showcaseClientIcon, showcaseExperienceIcon, showcaseVehiclesIcon],
    OUTDOOR_INTERIORS: [outdoorClientIcon, outdoorExperienceIcon, outdoorVehiclesIcon],
  };

  

  // Get the current category data
  const stats = data[currentFilter];
  const categoryLabels = labels[currentFilter];
  const categoryIcons = icons[currentFilter];

  return (
    <section className="counter-section stack" >
      <div className="container" >
        {/* <h2 style={{ textAlign: "center", marginBottom: "-125px" }}>
          {currentFilter.replace("_", " ")}
        </h2> */}
        <div className="row" style={{marginTop:'7px', height:'225px'}}>
          {Object.keys(stats).map((key, index) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={key}>
              <div className="counter-count">
                <img src={categoryIcons[index]} alt={key} height="50px" />
                <span className="count">
                  <CountUp start={0} end={stats[key]} duration={2.5} />
                </span>
                <h3>{categoryLabels[key]}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Internal CSS */}
      <style>{`
        .counter-count {
          position: relative;
          background: rgba(0, 0, 0, 0.4);
          padding: 20px;
          border-radius: 100px;
          text-align: center;
          transition: all 0.4s ease-in-out;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          height: 187px;
        }

        .counter-count:hover {
          transform: translateY(-10px);
          background: rgba(0, 0, 0, 0.6);
        }

        .count {
          font-size: 20px;
          font-weight: bold;
          color: white;
        }

        h3 {
          color: white;
          margin-top: 10px;
        }
      `}</style>
    </section>
  );
};

export default FullStack;
