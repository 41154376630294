// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'; // Import solid icons

// import { Link } from 'react-router-dom';
// import logo from '../img/banner/SRI SHIVA TRAVELS LOGO_PNG-01.png';

// const Footer = () => {
//   return (
//     <footer>
//       <div className="container-fluid">
//         <div className="row p-3-vh">
//           <div className="col-md-4">
//             <div className="logo d-flex align-items-center">
//               {/* <img alt="logo" src={logo} style={{ maxHeight: '78px' }} /> */}
//               <div className="ml-3 text-white" style={{marginTop:'-68px'}}> {/* Added text-white class for white text */}
//               <img className="h-150 init" alt="logo" src={logo} style={{ height: "75px", width: "auto" }} />
//               <span style={{ fontSize: "18", fontWeight: "bold", color: "rgb(235, 195, 101)",fontFamily:'Raqupine Regular' }}>
//     SRI SHIVA TRAVELS
//   </span>
 
//                 <p className="m-0"></p>
//               </div>
//             </div>
//             <div className="description">
//               "Driving Your Logistics Forward with Smart Solutions."
//             </div>
//             <div className="s-social">
//               {/* Social Icons */}
//               <a href="#"><span className="ti-facebook"></span></a>
//               <a href="#"><span className="ti-linkedin"></span></a>
//               <a href="#"><span className="ti-instagram"></span></a>
//               <a href="#"><span className="ti-youtube"></span></a>
//             </div>
//           </div>

//           {/* <div className="col-md-3">
//             <div className="heading">Services</div>
//             <div className="s-address">
//               <div className="detail">

//                 <Link to="/web-mobile-applications">Warehousing </Link>
//               </div>
//               <div className="detail">

//                 <Link to="/it-services"> Road Transportaion</Link>
//               </div>
//               <div className="detail">


//                 <Link to="/gaming">Imports & Exports</Link>
//               </div>
              

//             </div>
//           </div> */}

//           <div className="col-md-2">
//             <div className="heading">Quick Links</div>
//             <div className="s-address">
//               <div className="detail">
//                 <Link to="/">Home</Link>
//               </div>
//               <div className="detail">
//                 <Link to="/aboutpage">Who We Are</Link>
//               </div>
//               <div className="detail">
//                 <Link to="/what-we-do">What We Do</Link>
//               </div> 
//                {/* <div className="detail">
//                 <Link to="/gellerypage">Gallery</Link>
//               </div> */}



//               {/* <div className="detail">
//                 <a href="gallery.php">Gallery</a>
//               </div> */}
//               <div className="detail">
//                 <Link to="/contact">Contact Us</Link>
//               </div>
//             </div>
//           </div>

//           <div className="col-md-3">
//             <div className="heading">Get In Touch</div>
//             <div className="s-address">
//               <div className="detail">
//                 <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: '10px' }} />
//                 contact@logistics.com
//               </div>
//               <div className="detail">
//                 <FontAwesomeIcon icon={faPhone} style={{ paddingRight: '10px' }} />
//                 (330) 474-9389
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="row p-3-vh">
//           <div className="col-12">
//             <div className="subfooter">
//               <div className="copyright text-center">
//                 <p>Copyright©2024  <span style={{ fontFamily: 'Raqupine Regular', color: 'rgb(235, 195, 101)' }}> SRI SHIVA TRAVELS </span>, LLC. All Rights Reserved.</p>
//               </div>
//               <a className="terms" href="#">
//                 Terms of use | Privacy Environmental Policy.
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* WhatsApp Button */}
//       {/* <a href="https://wa.me/ (330) 474-9389" className="whatsapp-button" target="_blank" rel="noopener noreferrer">
//         <FontAwesomeIcon icon={faWhatsapp} className="fab fa-whatsapp" style={{ fontSize: "30px", color: "#fff" }} />
//       </a> */}
//     </footer>
//   );
// };

// export default Footer;




import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone,faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logo from "../img/banner/SRI SHIVA TRAVELS LOGO_PNG-01.png";

const Footer = () => {
  return (
    <footer style={{ background: "rgb(47 56 64)", padding: "20px 0" }}>
      <div className="container-fluid">
        {/* Top Left Icons */}
        

        {/* Main Footer Content */}
        <div className="row p-2">
  {/* Quick Links */}
  <div className="col-md-3">
    <div className="heading" style={{fontFamily:'roboto',color:"rgb(235, 195, 101)"}}>Quick Links</div>
    <div className="s-address" style={{fontFamily:'roboto',fontWeight:'bold'}}>
      <div className="detail"><Link to="/">Home</Link></div>
      <div className="detail"><Link to="/aboutpage">Who We Are</Link></div>
      <div className="detail"><Link to="/what-we-do">What We Do</Link></div>
      <div className="detail"><Link to="/contact">Contact Us</Link></div>
    </div>
  </div>

  {/* Location Section */}
  
  {/* Get In Touch */}
  <div className="col-md-3">
    <div className="heading" style={{fontFamily:'roboto',color:"rgb(235, 195, 101)"}}>Get In Touch</div>
    <div className="s-address">
      <div className="detail">
        <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: "10px" }} />
        sandeep@srishivatravels.com
      </div>
      <div className="detail">
        <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: "10px" }} />
        jai@srishivatravels.com
      </div>
      <div className="detail">
        <FontAwesomeIcon icon={faPhone} style={{ paddingRight: "10px" }} />
        040-4971039
      </div>
    </div>
  </div>

  <div className="col-md-3">
    <div className="heading" style={{fontFamily:'roboto',color:"rgb(235, 195, 101)"}}>Location</div>
    <div className="s-address">
      <div className="detail">
       
        <div style={{fontFamily:'roboto',}}>
        House No. 2-48/9/401,Nova
Jyot Apartment, Telecom Nagar, Urdu University, Gachibowli, Serilingampally, Rangareddy, Telangana, 500032
  
          <br />
          <strong>Office No.:</strong> 040-4971039
        </div>
      </div>
    </div>
  </div>
  <div className="row">
          <div className="col-md-12 d-flex justify-content-start align-items-center">
            <div className="s-social">
              <a href="#"><span className="ti-facebook"></span></a>
              <a href="#"><span className="ti-linkedin"></span></a>
              <a href="#"><span className="ti-instagram"></span></a>
              <a href="#"><span className="ti-youtube"></span></a>
            </div>
          </div>
        </div>
  </div>


        {/* Second Footer (Copyright + Logo) */}
        <div className="row p-2" style={{ borderTop: "1px solid #444", paddingTop: "10px",height:'69px' }}>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="copyright text-white">
              
              <p>
              <img src={logo} alt="logo" style={{ height: "50px", width: "auto" }} />
                Copyright © 2025{" "}
                <span style={{ fontFamily: "Raqupine Regular", color: "rgb(235, 195, 101)" }}>
                  SRI SHIVA TRAVELS
                </span>
                , LLC. All Rights Reserved.

                
              </p>
            </div>
           
           
          </div>
        </div>
      </div>

    
      {/* <a href="https://wa.me/ (330) 474-9389" className="whatsapp-button" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "30px", color: "#fff", position: "fixed", bottom: "30px", right: "20px" }} />
      </a> */}

      {/* Internal CSS */}
      <style>{`
        .s-social a {
          color: white;
          font-size: 18px;
          margin-right: 15px;
          transition: 0.3s;
        }
        .s-social a:hover {
          color: rgb(235, 195, 101);
        }
        .heading {
          font-weight: bold;
          font-size: 18px;
          color: white;
          margin-bottom: 10px;
        }
        .s-address .detail {
          color: white;
          margin-bottom: 8px;
        }
      `}</style>
    </footer>
  );
};

export default Footer;







// import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from 'react-router-dom';
// import {
//   faFacebook,
//   faLinkedin,
//   faInstagram,
//   faYoutube,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import logo from "../img/banner/SRI SHIVA TRAVELS LOGO_PNG-01.png";

// const Footer = () => {
//   const [showLanguageModal, setShowLanguageModal] = useState(false);

//   // Open Language Modal
//   const handleLanguageClick = () => {
//     setShowLanguageModal(true);
//   };

//   // Close Language Modal
//   const handleCloseModal = () => {
//     setShowLanguageModal(false);
//   };

//   return (
//     <footer style={styles.footer}>
//       <div className="container-fluid">
//         {/* Social Icons */}
//         {/* <div style={styles.socialIcons}>
//           <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faFacebook} style={styles.icon} />
//           </a>
//           <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faLinkedin} style={styles.icon} />
//           </a>
//           <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faInstagram} style={styles.icon} />
//           </a>
//           <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faYoutube} style={styles.icon} />
//           </a>
//           <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faTwitter} style={styles.icon} />
//           </a>
//         </div> */}

//         {/* Footer Links */}

//         <div style={styles.socialIcons}>
//           <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faFacebook} style={styles.icon} />
//           </a>
//           <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faLinkedin} style={styles.icon} />
//           </a>
//           <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faInstagram} style={styles.icon} />
//           </a>
//           <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faYoutube} style={styles.icon} />
//           </a>
//           <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faTwitter} style={styles.icon} />
//           </a>
//         </div>
//         <div style={styles.row}>
        
          
//           {/* <div style={styles.column}>
//             <h5 style={styles.heading}>About</h5>
//             <ul style={styles.list}>
            
//               <li>  <a href="https://about.iiautomate.com/" target="_blank" rel="noopener noreferrer">
//                About Us
//                 </a></li>
//               <li>
//                 <a href="https://dashboard.iiautomate.com/" target="_blank" rel="noopener noreferrer">
//                   Dashboard
//                 </a>
//               </li>
//               <li><Link to="/contact">Contact Us</Link></li>


//             </ul>
//           </div> */}



//           <div style={styles.column}>
//             <h5 style={styles.heading}>IT Cources</h5>
//             <ul style={styles.list}>
            
//                  <Link to="/">Home</Link>
//                  <Link to="/aboutpage">Who We Are</Link>
//                 <li><Link to="/">React.js</Link></li>
//                 <li><Link to="/">Flutter App Development</Link></li>
                
                
          
           
//             </ul>
//           </div>
//           <div style={styles.column}>
//             <h5 style={styles.heading}>Jose</h5>
//             <ul style={styles.list}>
//               <li><Link to="/">Career Foundation Skills</Link></li>
//               <li><Link to="/">Soft Skills Development</Link></li>
//               <li><Link to="/">Digital Presence Skills </Link></li>
              

//             </ul>
//           </div>
//           {/* <div style={styles.column}>
//             <h5 style={styles.heading}>Our Location</h5>
//             <ul style={styles.list}>
//               <li>Hyderabad</li>
              
//             </ul>
//           </div> */}
//         </div>
        
//         <hr style={styles.line} />
//         {/* Subfooter */}
//         <div className="ffft" style={styles.subfooter}>
//           {/* Logo */}
//           <div className="" style={styles.logoTextContainer}>
//             <div style={styles.logoContainer}>
//               <img src={logo} alt="Logo" style={styles.logo} />
//             </div>

//             <p style={styles.logoText}></p>
//           </div>



//           {/* Center Text */}
//           <div style={styles.subfooterText}>
//             <p>
//               Copyright © 2024, All Rights Reserved To <strong>INTERNATIONAL INSTITUTE OF AUTOMATE</strong> {" "}
//               <strong></strong>
           
//             <a href="#" style={{ color: "#fff" }}>
//               Terms of Use | Privacy Environmental Policy
//             </a>
//             </p>
//           </div>

//           {/* Language Button */}
//           <div style={styles.languageContainer}>
//             <button onClick={handleLanguageClick} style={styles.languageButton}>
//               🌐
//             </button>
//           </div>
//         </div>

//         {/* Language Modal */}
//         {showLanguageModal && (
//           <div style={modalStyles.overlay}>
//             <div style={modalStyles.modal}>
//               <h3 style={modalStyles.title}>Select Language</h3>
//               <ul style={modalStyles.languageList}>
//                 <li style={modalStyles.languageItem}>English</li>
//                 <li style={modalStyles.languageItem}>Hindi</li>
//                 <li style={modalStyles.languageItem}>Spanish</li>
//                 <li style={modalStyles.languageItem}>French</li>
//                 <li style={modalStyles.languageItem}>German</li>
//                 <li style={modalStyles.languageItem}>Chinese</li>
//               </ul>
//               <button onClick={handleCloseModal} style={modalStyles.closeButton}>
//                 Close
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </footer>
//   );
// };

// const styles = {

//   footer: {
//     backgroundColor: '#313840',
//     color: '#fff',
//     padding: '20px 0',
//     fontSize: '16px',
//     fontFamily: "Roboto",
//     fontWeight: '300',
    
//   },
//   socialIcons: {
//     display: 'flex',
//     justifyContent: 'flex-start',
//     marginBottom: '10px',
//   },
//   icon: {
//     fontSize: '20px',
//     color: '#fff',
//     margin: '0 10px',
//     cursor: 'pointer',
//   },
//   row: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     flexWrap: 'wrap',
//     marginBottom: '20px',
//   },
//   column: {
//     flex: '1',
//     // margin: ' 5px',
//     minWidth: '200px',
//   },
//   heading: {
//     fontSize: '18px',
//     fontWeight: '400',
//     marginBottom: '10px',
//     color:'orange',
//   },
//   line: {
//     border: 'none',
//     borderBottom: '1px solid #fff',
//     marginBottom: '0px',
//   },
//   list: {
//     listStyle: 'none',
//     padding: 0,
//   },
//   listItem: {
//     marginBottom: '5px',
//   },
//   subfooter: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     backgroundColor: '#313840',
//     padding: '10px 20px',
//     color: '#fff',
//   },
//   logoContainer: {
//     flex: 1,
//     textAlign: 'left',
//   },
//   logo: {
//     height: '40px', // Adjust the height as needed
//   },
//   logoTextContainer: {
//     display: 'flex',
//     alignItems: 'center',
//     textAlign: 'left',
//   },
//   subfooterText: {
//     flex: 2,
//     textAlign: 'center',
//     fontFamily: 'roboto',

//   },
//   languageContainer: {
//     // flex: 1,
//     textAlign: 'right',
//   },
//   languageButton: {
//     background: 'transparent',
//     border: 'none',
//     color: '#fff',
//     cursor: 'pointer',
//     fontSize: '30px',
//     marginRight: '20px',
//   },
// };

// const modalStyles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1000,
//   },
//   modal: {
//     backgroundColor: "#fff",
//     borderRadius: "8px",
//     padding: "20px",
//     width: "300px",
//     textAlign: "center",
//   },
//   title: {
//     fontSize: "20px",
//     marginBottom: "15px",
//   },
//   languageList: {
//     listStyle: "none",
//     padding: 0,
//     margin: "10px 0",
//   },
//   languageItem: {
//     margin: "5px 0",
//     padding: "3px 10px",
//     cursor: "pointer",
//     borderRadius: "4px",
//     backgroundColor: "#313840",
//     textAlign: "center",
//   },
//   closeButton: {
//     marginTop: "20px",
//     padding: "10px 20px",
//     backgroundColor: "#007BFF",
//     color: "#fff",
//     border: "none",
//     cursor: "pointer",
//     borderRadius: "4px",
//   },
// };

// export default Footer;
